import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { UserService } from '../../../services/user.service';
import { TableDataService } from '../../../services/table-data.services';
import { forEach } from '@angular/router/src/utils/collection';
import Swal from 'sweetalert2';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-cuantitativo-report',
  templateUrl: './cuantitativo-report.component.html',

  styleUrls: ['./cuantitativo-report.component.css']
})
export class CuantitativoReportComponent {

  heading = 'Reporte';
  private socialList: any = [];
  private accountList: any = [];
  private projectId: number;
  private projectName: string;
  private imageUrl: string = '';
  private selectedSocial: any = [];
  private selectedAccountIds: any = [];
  private selectedAccount: any;

  private facebookData: any = [];
  private twitterData: any = [];
  private instagramData: any = [];
  private youtubeData: any = [];
  private TikTokData: any = [];

  public dateFrom: NgbDateStruct;
  public dateTo: NgbDateStruct;
  public today: string;
  public showSocial: any = [false, false, false, false,false] ;



  constructor(private userService: UserService, private tableDataService: TableDataService, private route: ActivatedRoute, private router: Router) {

  }


  ngOnInit() {

    
        

    this.socialList = [
      { id: 1, name: 'FB' },
      { id: 2, name: 'TW' },
      { id: 3, name: 'IG' },
      { id: 4, name: 'YT' },
      { id: 5, name: 'TK' },
    ];

    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];
      this.imageUrl = params['imageUrl'];
    });

    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }

    let data = {
      projectId : this.projectId
    }

    this.userService
      .getAccount(data)
      .subscribe((res:any)=>{
        this.accountList = res;
      },
      err => {
      }
    );
  }

  
  changeFont() {
    //operator === '+' ? this.fontSize++ : this.fontSize--;
    //(this.f1.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;
    if(this.showSocial[0]){
      let maxlistF1 = [];
      let maxlistF2 = [];
      let maxlistF3 = [];
      let maxlistF4 = [];
      let maxlistF5 = [];
      let maxlistF6 = [];
      let maxlistF7 = [];
      let maxlistF8 = [];

      $('.f1').each(function(i, obj) {
        maxlistF1.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f2').each(function(i, obj) {
        maxlistF2.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f3').each(function(i, obj) {
        maxlistF3.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f4').each(function(i, obj) {
        maxlistF4.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f5').each(function(i, obj) {
        maxlistF5.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f6').each(function(i, obj) {
        maxlistF6.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f7').each(function(i, obj) {
        maxlistF7.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.f8').each(function(i, obj) {
        maxlistF8.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });

      let maxF1 = Math.max.apply(null, maxlistF1);
      let maxF2 = Math.max.apply(null, maxlistF2);
      let maxF3 = Math.max.apply(null, maxlistF3);
      let maxF4 = Math.max.apply(null, maxlistF4);
      let maxF5 = Math.max.apply(null, maxlistF5);
      let maxF6 = Math.max.apply(null, maxlistF6);
      let maxF7 = Math.max.apply(null, maxlistF7);
      let maxF8 = Math.max.apply(null, maxlistF8);

      let indexF1 = maxlistF1.indexOf(maxF1);
      let indexF2 = maxlistF2.indexOf(maxF2);
      let indexF3 = maxlistF3.indexOf(maxF3);
      let indexF4 = maxlistF4.indexOf(maxF4);
      let indexF5 = maxlistF5.indexOf(maxF5);
      let indexF6 = maxlistF6.indexOf(maxF6);
      let indexF7 = maxlistF7.indexOf(maxF7);
      let indexF8 = maxlistF8.indexOf(maxF8);

      document.getElementsByClassName('f1')[indexF1].innerHTML += ' 🥇'
      document.getElementsByClassName('f2')[indexF2].innerHTML += ' 🥇'
      document.getElementsByClassName('f3')[indexF3].innerHTML += ' 🥇'
      document.getElementsByClassName('f4')[indexF4].innerHTML += ' 🥇'
      document.getElementsByClassName('f5')[indexF5].innerHTML += ' 🥇'
      document.getElementsByClassName('f6')[indexF6].innerHTML += ' 🥇'
      document.getElementsByClassName('f7')[indexF7].innerHTML += ' 🥇'
      document.getElementsByClassName('f8')[indexF8].innerHTML += ' 🥇'
    }
    if(this.showSocial[1]){
      let maxlistTW1 = [];
      let maxlistTW2 = [];
      let maxlistTW3 = [];
      let maxlistTW4 = [];
      let maxlistTW5 = [];
      let maxlistTW6 = [];
      let maxlistTW7 = [];
      let maxlistTW8 = [];
      
      $('.TW1').each(function(i, obj) {
        maxlistTW1.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW2').each(function(i, obj) {
        maxlistTW2.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW3').each(function(i, obj) {
        maxlistTW3.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW4').each(function(i, obj) {
        maxlistTW4.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW5').each(function(i, obj) {
        maxlistTW5.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW6').each(function(i, obj) {
        maxlistTW6.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW7').each(function(i, obj) {
        maxlistTW7.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.TW8').each(function(i, obj) {
        maxlistTW8.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });

      let maxTW1 = Math.max.apply(null, maxlistTW1);
      let maxTW2 = Math.max.apply(null, maxlistTW2);
      let maxTW3 = Math.max.apply(null, maxlistTW3);
      let maxTW4 = Math.max.apply(null, maxlistTW4);
      let maxTW5 = Math.max.apply(null, maxlistTW5);
      let maxTW6 = Math.max.apply(null, maxlistTW6);
      let maxTW7 = Math.max.apply(null, maxlistTW7);
      let maxTW8 = Math.max.apply(null, maxlistTW8);

      let indexTW1 = maxlistTW1.indexOf(maxTW1);
      let indexTW2 = maxlistTW2.indexOf(maxTW2);
      let indexTW3 = maxlistTW3.indexOf(maxTW3);
      let indexTW4 = maxlistTW4.indexOf(maxTW4);
      let indexTW5 = maxlistTW5.indexOf(maxTW5);
      let indexTW6 = maxlistTW6.indexOf(maxTW6);
      let indexTW7 = maxlistTW7.indexOf(maxTW7);
      let indexTW8 = maxlistTW8.indexOf(maxTW8);

      document.getElementsByClassName('TW1')[indexTW1].innerHTML += ' 🥇'
      document.getElementsByClassName('TW2')[indexTW2].innerHTML += ' 🥇'
      document.getElementsByClassName('TW3')[indexTW3].innerHTML += ' 🥇'
      document.getElementsByClassName('TW4')[indexTW4].innerHTML += ' 🥇'
      document.getElementsByClassName('TW5')[indexTW5].innerHTML += ' 🥇'
      document.getElementsByClassName('TW6')[indexTW6].innerHTML += ' 🥇'
      document.getElementsByClassName('TW7')[indexTW7].innerHTML += ' 🥇'
      document.getElementsByClassName('TW8')[indexTW8].innerHTML += ' 🥇'
    }
    if(this.showSocial[2]){
    let maxlistIG1 = [];
    let maxlistIG2 = [];
    let maxlistIG3 = [];
    let maxlistIG4 = [];
    let maxlistIG5 = [];
    let maxlistIG6 = [];
    let maxlistIG7 = [];
    //let maxlistIG8 = [];
    
    $('.ig1').each(function(i, obj) {
      maxlistIG1.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig2').each(function(i, obj) {
      maxlistIG2.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig3').each(function(i, obj) {
      maxlistIG3.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig4').each(function(i, obj) {
      maxlistIG4.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig5').each(function(i, obj) {
      maxlistIG5.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig6').each(function(i, obj) {
      maxlistIG6.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
    });
    $('.ig7').each(function(i, obj) {
      maxlistIG7.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0").replace("","0")))
    });
    /*$('.ig8').each(function(i, obj) {
      maxlistIG8.push(parseFloat($(this).text().replace(/,/g, '')))
    });*/

    let maxIG1 = Math.max.apply(null, maxlistIG1);
    let maxIG2 = Math.max.apply(null, maxlistIG2);
    let maxIG3 = Math.max.apply(null, maxlistIG3);
    let maxIG4 = Math.max.apply(null, maxlistIG4);
    let maxIG5 = Math.max.apply(null, maxlistIG5);
    let maxIG6 = Math.max.apply(null, maxlistIG6);
    let maxIG7 = Math.max.apply(null, maxlistIG7);
    //let maxIG8 = Math.max.apply(null, maxlistIG8);

    let indexIG1 = maxlistIG1.indexOf(maxIG1);
    let indexIG2 = maxlistIG2.indexOf(maxIG2);
    let indexIG3 = maxlistIG3.indexOf(maxIG3);
    let indexIG4 = maxlistIG4.indexOf(maxIG4);
    let indexIG5 = maxlistIG5.indexOf(maxIG5);
    let indexIG6 = maxlistIG6.indexOf(maxIG6);
    let indexIG7 = maxlistIG7.indexOf(maxIG7);
    //let indexIG8 = maxlistIG8.indexOf(maxIG8);

    document.getElementsByClassName('ig1')[indexIG1].innerHTML += ' 🥇'
    document.getElementsByClassName('ig2')[indexIG2].innerHTML += ' 🥇'
    document.getElementsByClassName('ig3')[indexIG3].innerHTML += ' 🥇'
    document.getElementsByClassName('ig4')[indexIG4].innerHTML += ' 🥇'
    document.getElementsByClassName('ig5')[indexIG5].innerHTML += ' 🥇'
    document.getElementsByClassName('ig6')[indexIG6].innerHTML += ' 🥇'
    document.getElementsByClassName('ig7')[indexIG7].innerHTML += ' 🥇'
    //document.getElementsByClassName('ig8')[indexIG8].innerHTML += ' 🥇'
    }
    if(this.showSocial[3]){
      let maxlistYT1 = [];
      let maxlistYT2 = [];
      let maxlistYT3 = [];
      let maxlistYT4 = [];
      let maxlistYT5 = [];
      //let maxlistYT6 = [];
      //let maxlistYT7 = [];
      //let maxlistYT8 = [];
      
      $('.yt1').each(function(i, obj) {
        maxlistYT1.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.yt2').each(function(i, obj) {
        maxlistYT2.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.yt3').each(function(i, obj) {
        maxlistYT3.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.yt4').each(function(i, obj) {
        maxlistYT4.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.yt5').each(function(i, obj) {
        maxlistYT5.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0").replace('',"0")))
      });
      /*$('.yt6').each(function(i, obj) {
        maxlistYT6.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.yt7').each(function(i, obj) {
        maxlistYT7.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0".replace('',"0"))))
      });
      $('.yt8').each(function(i, obj) {
        maxlistYT8.push(parseFloat($(this).text().replace(/,/g, '')))
      });*/
  
      let maxYT1 = Math.max.apply(null, maxlistYT1);
      let maxYT2 = Math.max.apply(null, maxlistYT2);
      let maxYT3 = Math.max.apply(null, maxlistYT3);
      let maxYT4 = Math.max.apply(null, maxlistYT4);
      let maxYT5 = Math.max.apply(null, maxlistYT5);
      //let maxYT6 = Math.max.apply(null, maxlistYT6);
      //let maxYT7 = Math.max.apply(null, maxlistYT7);
      //let maxYT8 = Math.max.apply(null, maxlistYT8);
  
      let indexYT1 = maxlistYT1.indexOf(maxYT1);
      let indexYT2 = maxlistYT2.indexOf(maxYT2);
      let indexYT3 = maxlistYT3.indexOf(maxYT3);
      let indexYT4 = maxlistYT4.indexOf(maxYT4);
      let indexYT5 = maxlistYT5.indexOf(maxYT5);
      //let indexYT6 = maxlistYT6.indexOf(maxYT6);
      //let indexYT7 = maxlistYT7.indexOf(maxYT7);
      //let indexYT8 = maxlistYT8.indexOf(maxYT8);
  
      document.getElementsByClassName('yt1')[indexYT1].innerHTML += ' 🥇'
      document.getElementsByClassName('yt2')[indexYT2].innerHTML += ' 🥇'
      document.getElementsByClassName('yt3')[indexYT3].innerHTML += ' 🥇'
      document.getElementsByClassName('yt4')[indexYT4].innerHTML += ' 🥇'
      document.getElementsByClassName('yt5')[indexYT5].innerHTML += ' 🥇'
      //document.getElementsByClassName('yt6')[indexYT6].innerHTML += ' 🥇'
      //document.getElementsByClassName('yt7')[indexYT7].innerHTML += ' 🥇'
      //document.getElementsByClassName('yt8')[indexYT8].innerHTML += ' 🥇'
    }
    if(this.showSocial[4]){
      let maxlistTK1 = [];
      let maxlistTK2 = [];
      let maxlistTK3 = [];
      let maxlistTK4 = [];
      let maxlistTK5 = [];
      let maxlistTK6 = [];
      //let maxlistTK7 = [];
      //let maxlistTK8 = [];
      
      $('.tk1').each(function(i, obj) {
        maxlistTK1.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk2').each(function(i, obj) {
        maxlistTK2.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk3').each(function(i, obj) {
        maxlistTK3.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk4').each(function(i, obj) {
        maxlistTK4.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk5').each(function(i, obj) {
        maxlistTK5.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk6').each(function(i, obj) {
        maxlistTK6.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0").replace('','0')))
      });
      /*$('.tk7').each(function(i, obj) {
        maxlistTK7.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });
      $('.tk8').each(function(i, obj) {
        maxlistTK8.push(parseFloat($(this).text().replace(/,/g, '').replace(/-/gi,"0")))
      });*/
  
      let maxTK1 = Math.max.apply(null, maxlistTK1);
      let maxTK2 = Math.max.apply(null, maxlistTK2);
      let maxTK3 = Math.max.apply(null, maxlistTK3);
      let maxTK4 = Math.max.apply(null, maxlistTK4);
      let maxTK5 = Math.max.apply(null, maxlistTK5);
      let maxTK6 = Math.max.apply(null, maxlistTK6);
      //let maxTK7 = Math.max.apply(null, maxlistTK7);
      //let maxTK8 = Math.max.apply(null, maxlistTK8);
  
      let indexTK1 = maxlistTK1.indexOf(maxTK1);
      let indexTK2 = maxlistTK2.indexOf(maxTK2);
      let indexTK3 = maxlistTK3.indexOf(maxTK3);
      let indexTK4 = maxlistTK4.indexOf(maxTK4);
      let indexTK5 = maxlistTK5.indexOf(maxTK5);
      let indexTK6 = maxlistTK6.indexOf(maxTK6);
      //let indexTK7 = maxlistTK7.indexOf(maxTK7);
      //let indexTK8 = maxlistTK8.indexOf(maxTK8);
  
      document.getElementsByClassName('tk1')[indexTK1].innerHTML += ' 🥇'
      document.getElementsByClassName('tk2')[indexTK2].innerHTML += ' 🥇'
      document.getElementsByClassName('tk3')[indexTK3].innerHTML += ' 🥇'
      document.getElementsByClassName('tk4')[indexTK4].innerHTML += ' 🥇'
      document.getElementsByClassName('tk5')[indexTK5].innerHTML += ' 🥇'
      document.getElementsByClassName('tk6')[indexTK6].innerHTML += ' 🥇'
      //document.getElementsByClassName('tk7')[indexTK7].innerHTML += ' 🥇'
      //document.getElementsByClassName('tk8')[indexTK8].innerHTML += ' 🥇'
    }
    
  }

  selectDateFrom() {
    //this.getTableData();
    /*console.log(this.dateFrom)
    let dia = (new Date().getUTCDate() -1);
    let mes = (new Date().getMonth() + 1)
    let anio = new Date().getFullYear()
    console.log(dia,"-",mes,"-",anio)*/

    //this.getTableData();
    

  }

  selectDateTo() {
    //this.getTableData();
    console.log(this.dateTo)
    //console.log(this.dateFrom)
    let dia = (new Date().getUTCDate() -1);
    let mes = (new Date().getMonth() + 1)
    let anio = new Date().getFullYear()
    //console.log(dia,"-",mes,"-",anio)
    if(this.dateTo.year > anio || this.dateTo.month > mes || this.dateTo.day >= dia){
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡La fecha debe se un día anterior a hoy!',
        //footer: '<a href="">Why do I have this issue?</a>'
      })
      this.dateTo.day = dia -1;
      this.dateTo.month = mes;
      this.dateTo.year = anio;
    }
  }

  setClass() {
    let strClass: any = {}
    if (this.selectedAccount.length != 0)
      if (this.selectedAccount.length == 1) {
        strClass['col-md-12'] = true;
      } else if (this.selectedAccount.length == 2) {
        strClass['col-md-6'] = true;
      } else if (this.selectedAccount.length == 3) {
        strClass['col-md-4'] = true;
      } else {
        strClass['col-md-3'] = true;
      }
    return strClass;
  }
  
  formatNum(numm){
    let numberFormat = new Intl.NumberFormat('es-MX');
    return numberFormat.format(numm);
  }

  socialSelect() {
    this.showSocial[0] = this.selectedSocial.indexOf(1) == -1? false : true;
    this.showSocial[1] = this.selectedSocial.indexOf(2) == -1? false : true;
    this.showSocial[2] = this.selectedSocial.indexOf(3) == -1? false : true;
    this.showSocial[3] = this.selectedSocial.indexOf(4) == -1? false : true;
    this.showSocial[4] = this.selectedSocial.indexOf(5) == -1? false : true;

    //this.getTableData();
    
  }

  setAbbreviation(item) {
    let temp: any = item.name.split(' ');
    let result: string = '';
    for (let index = 0; index < temp.length; index++) {
     result += temp[index].substr(0,1);
    }
    return result;
  }

  accountSelect() {
    
    if (this.selectedAccountIds != undefined && this.selectedAccountIds.length > 4) {
      let temp : any = [];
      for (let index = 0; index < 4; index++) {
        temp.push(this.selectedAccountIds[index]);
      }
      this.selectedAccountIds = temp;
      console.log("TEMP1", temp)
    }

    if (this.selectedAccountIds.length != 0) {
      this.selectedAccount = [];
      console.log("selectedAccount",this.selectedAccount)
      console.log("accountList",this.accountList)
      console.log("selectedAccountIds",this.selectedAccountIds)

      for (let index = 0; index < this.selectedAccountIds.length; index++) {
        //let giveIndex = this.accountList.indexOf(this.selectedAccountIds[index])
        let giveIndex = this.accountList.findIndex(std=> std.id == this.selectedAccountIds[index]);
        if(giveIndex!= -1) {
          this.selectedAccount.push(this.accountList[giveIndex]);
          //sE DEBE PONERALGUNDISTINTIVODE QUE LA CUENTA NO CONTIENE ALGUN DATO
          if(this.accountList[giveIndex].idQFB == 0 ||
            this.accountList[giveIndex].idQTW == 0 ||
            this.accountList[giveIndex].idQIG == 0 ||
            this.accountList[giveIndex].idQYT == 0 ||
            this.accountList[giveIndex].idQTK == 0){
           Swal.fire({
             title: '¿Está seguro?',
             html: `${this.accountList[giveIndex].name} no tiene asignado un ID de alguna red social para consultar 
             datos por lo que puedecausar errores.<br>
             <br>FB: ${this.accountList[giveIndex].idQFB}
             <br>TW: ${this.accountList[giveIndex].idQTW}
             <br>IG: ${this.accountList[giveIndex].idQIG}
             <br>YT: ${this.accountList[giveIndex].idQYT}
             <br>TK: ${this.accountList[giveIndex].idQTK}`,
             icon: 'warning'
           })
         }
        }
      }
      console.log("TEMP2", this.selectedAccount)
    }
    
    //this.getTableData();
  }
  
  drawFBChart(datos){
    let newData =[];


    datos.forEach(element1 => {
        let index = newData.findIndex(std=> std.id == element1.PROFILEID);
        if(index != -1){
          newData[index].data.push(element1);
        }else{
          newData.push({
            name: 'name',
            id: element1.PROFILEID,
            data: [element1]
          })
        }

      
    });
    //console.log(this.accountSelect);
    this.selectedAccount.forEach(element => {
      let index = newData.findIndex(std=> std.id == element.idQFB);
      newData[index].name = element.name;      
    });
    console.log("newData",newData)


    // Create chart instance
    //let chart = am4core.create("chartFBInt", am4charts.XYChart);
    var chart = am4core.create("chartFBInt", am4charts.XYChart);

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    for (var i = 0; i < newData.length; i++) {
      createSeries(i, newData[i].name);
    }

    // Create series
    function createSeries(s, name) {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value" + s;
      series.dataFields.dateX = "date";
      series.name = name;

      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 3;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      segment.events.on("over", function(event) {
        processOver(event.target.parent.parent.parent);
      });

      segment.events.on("out", function(event) {
        processOut(event.target.parent.parent.parent);
      });

      var data = [];

      var value = Math.round(Math.random() * 100) + 100;
      console.log(newData[s].data.length)
      for (var i = 0; i < newData[s].data.length; i++) {
        value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
        console.log(newData[s].data[i].FECHA)
        var dataItem = { date: newData[s].data[i].FECHA};
        console.log(newData[s].data[i].TOTALI)
        dataItem["value" + s] = newData[s].data[i].TOTALI;
        data.push(dataItem);
      }

      series.data = data;
      return series;
    }

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;
    chart.exporting.menu = new am4core.ExportMenu();


    

    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    chart.legend.itemContainers.template.events.on("over", function(event) {
      processOver(event.target.dataItem.dataContext);
    })

    chart.legend.itemContainers.template.events.on("out", function(event) {
      processOut(event.target.dataItem.dataContext);
    })

    function processOver(hoveredSeries) {
      hoveredSeries.toFront();

      hoveredSeries.segments.each(function(segment) {
        segment.setState("hover");
      })
      
      hoveredSeries.legendDataItem.marker.setState("default");
      hoveredSeries.legendDataItem.label.setState("default");

      chart.series.each(function(series) {
        if (series != hoveredSeries) {
          /*series.LineSegments.each(function(segment) {
            segment.setState("dimmed");
          })*/
          series.bulletsContainer.setState("dimmed");
          series.legendDataItem.marker.setState("dimmed");
          series.legendDataItem.label.setState("dimmed");
        }
      });
    }

    function processOut(ev) {
      chart.series.each(function(series) {
        /*series.segments.each(function(segment) {
          segment.setState("default");
        })*/
        series.bulletsContainer.setState("default");
        series.legendDataItem.marker.setState("default");
        series.legendDataItem.label.setState("default");
      });
    }


    /*/ Add simple bullet
    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.href = "https://www.amcharts.com/lib/images/star.svg";
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";*/

  }

  drawTWChart(datos){
    let newData =[];


    datos.forEach(element1 => {
        let index = newData.findIndex(std=> std.id == element1.PROFILEID);
        if(index != -1){
          newData[index].data.push(element1);
        }else{
          newData.push({
            name: 'name',
            id: element1.PROFILEID,
            data: [element1]
          })
        }

      
    });
    //console.log(this.accountSelect);
    this.selectedAccount.forEach(element => {
      let index = newData.findIndex(std=> std.id == element.idQTW);
      newData[index].name = element.name;      
    });
    //console.log("newData",newData)


    // Create chart instance
    //let chart = am4core.create("chartFBInt", am4charts.XYChart);
    var chart = am4core.create("chartTWInt", am4charts.XYChart);

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    for (var i = 0; i < newData.length; i++) {
      createSeries(i, newData[i].name);
    }

    // Create series
    function createSeries(s, name) {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value" + s;
      series.dataFields.dateX = "date";
      series.name = name;

      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 3;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      segment.events.on("over", function(event) {
        processOver(event.target.parent.parent.parent);
      });

      segment.events.on("out", function(event) {
        processOut(event.target.parent.parent.parent);
      });

      var data = [];

      var value = Math.round(Math.random() * 100) + 100;
      console.log(newData[s].data.length)
      for (var i = 0; i < newData[s].data.length; i++) {
        value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
        console.log(newData[s].data[i].FECHA)
        var dataItem = { date: newData[s].data[i].FECHA};
        console.log(newData[s].data[i].TOTALI)
        dataItem["value" + s] = newData[s].data[i].TOTALI;
        data.push(dataItem);
      }

      series.data = data;
      return series;
    }

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;
    chart.exporting.menu = new am4core.ExportMenu();


    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    chart.legend.itemContainers.template.events.on("over", function(event) {
      processOver(event.target.dataItem.dataContext);
    })

    chart.legend.itemContainers.template.events.on("out", function(event) {
      processOut(event.target.dataItem.dataContext);
    })

    function processOver(hoveredSeries) {
      hoveredSeries.toFront();

      hoveredSeries.segments.each(function(segment) {
        segment.setState("hover");
      })
      
      hoveredSeries.legendDataItem.marker.setState("default");
      hoveredSeries.legendDataItem.label.setState("default");

      chart.series.each(function(series) {
        if (series != hoveredSeries) {
          /*series.LineSegments.each(function(segment) {
            segment.setState("dimmed");
          })*/
          series.bulletsContainer.setState("dimmed");
          series.legendDataItem.marker.setState("dimmed");
          series.legendDataItem.label.setState("dimmed");
        }
      });
    }

    function processOut(ev) {
      chart.series.each(function(series) {
        /*series.segments.each(function(segment) {
          segment.setState("default");
        })*/
        series.bulletsContainer.setState("default");
        series.legendDataItem.marker.setState("default");
        series.legendDataItem.label.setState("default");
      });
    }


    /*/ Add simple bullet
    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.href = "https://www.amcharts.com/lib/images/star.svg";
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";*/

  }

  drawFBFanChart(datos){
    let newData =[];


    datos.forEach(element1 => {
        let index = newData.findIndex(std=> std.id == element1.PROFILEID);
        if(index != -1){
          newData[index].data.push(element1);
        }else{
          newData.push({
            name: 'name',
            id: element1.PROFILEID,
            data: [element1]
          })
        }

      
    });
    //console.log(this.accountSelect);
    this.selectedAccount.forEach(element => {
      let index = newData.findIndex(std=> std.id == element.idQFB);
      newData[index].name = element.name;      
    });
    //console.log("newData",newData)


    // Create chart instance
    //let chart = am4core.create("chartFBInt", am4charts.XYChart);
    var chart = am4core.create("chartFBFan", am4charts.XYChart);

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    for (var i = 0; i < newData.length; i++) {
      createSeries(i, newData[i].name);
    }

    // Create series
    function createSeries(s, name) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value" + s;
      series.dataFields.dateX = "date";
      series.name = name;
      

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;
      chart.cursor.snapToSeries = series;
      chart.exporting.menu = new am4core.ExportMenu();


      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 3;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      segment.events.on("over", function(event) {
        processOver(event.target.parent.parent.parent);
      });

      segment.events.on("out", function(event) {
        processOut(event.target.parent.parent.parent);
      });

      var data = [];

      //var value = Math.round(Math.random() * 100) + 100;
      //console.log(newData[s].data.length)
      for (var i = 0; i < newData[s].data.length; i++) {
        //value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
        //console.log(newData[s].data[i].FECHA)
        var dataItem = { date: newData[s].data[i].FECHA};
        //console.log(newData[s].data[i].TOTALI)
        dataItem["value" + s] = newData[s].data[i].TOTALI;
        data.push(dataItem);
      }

      series.data = data;
      return series;
    }

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;

    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    chart.legend.itemContainers.template.events.on("over", function(event) {
      processOver(event.target.dataItem.dataContext);
    })

    chart.legend.itemContainers.template.events.on("out", function(event) {
      processOut(event.target.dataItem.dataContext);
    })

    function processOver(hoveredSeries) {
      hoveredSeries.toFront();

      hoveredSeries.segments.each(function(segment) {
        segment.setState("hover");
      })
      
      hoveredSeries.legendDataItem.marker.setState("default");
      hoveredSeries.legendDataItem.label.setState("default");

      /*chart.series.each(function(series) {
        if (series != hoveredSeries) {
          series.LineSegments.each(function(segment) {
            segment.setState("dimmed");
          })
          series.bulletsContainer.setState("dimmed");
          series.legendDataItem.marker.setState("dimmed");
          series.legendDataItem.label.setState("dimmed");
        }
      });*/
    }

    function processOut(ev) {
      chart.series.each(function(series) {
        /*series.segments.each(function(segment) {
          segment.setState("default");
        })*/
        series.bulletsContainer.setState("default");
        series.legendDataItem.marker.setState("default");
        series.legendDataItem.label.setState("default");
      });
    }


    /*/ Add simple bullet
    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.href = "https://www.amcharts.com/lib/images/star.svg";
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";*/

  }

  drawTWFanChart(datos){
    let newData =[];


    datos.forEach(element1 => {
        let index = newData.findIndex(std=> std.id == element1.PROFILEID);
        if(index != -1){
          newData[index].data.push(element1);
        }else{
          newData.push({
            name: 'name',
            id: element1.PROFILEID,
            data: [element1]
          })
        }

      
    });
    //console.log(this.accountSelect);
    console.log("Antes TW newData",newData)
    this.selectedAccount.forEach(element => {
      let index = newData.findIndex(std=> std.id == element.idQTW);
      newData[index].name = element.name;      
    });
    console.log("newData",newData)


    // Create chart instance
    //let chart = am4core.create("chartFBInt", am4charts.XYChart);
    let chart = am4core.create("chartTWFan", am4charts.XYChart);

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    for (var i = 0; i < newData.length; i++) {
      createSeries(i, newData[i].name);
    }

    // Create series
    function createSeries(s, name) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value" + s;
      series.dataFields.dateX = "date";
      series.name = name;
      

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;
      chart.cursor.snapToSeries = series;
      chart.exporting.menu = new am4core.ExportMenu();


      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 3;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      segment.events.on("over", function(event) {
        processOver(event.target.parent.parent.parent);
      });

      segment.events.on("out", function(event) {
        processOut(event.target.parent.parent.parent);
      });

      var data = [];

      //var value = Math.round(Math.random() * 100) + 100;
      //console.log(newData[s].data.length)
      for (var i = 0; i < newData[s].data.length; i++) {
        //value += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 30 + i / 5);
        //console.log(newData[s].data[i].FECHA)
        var dataItem = { date: newData[s].data[i].FECHA};
        //console.log(newData[s].data[i].TOTALI)
        dataItem["value" + s] = newData[s].data[i].TOTALI;
        data.push(dataItem);
      }

      series.data = data;
      return series;
    }

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;

    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    chart.legend.itemContainers.template.events.on("over", function(event) {
      processOver(event.target.dataItem.dataContext);
    })

    chart.legend.itemContainers.template.events.on("out", function(event) {
      processOut(event.target.dataItem.dataContext);
    })

    function processOver(hoveredSeries) {
      hoveredSeries.toFront();

      hoveredSeries.segments.each(function(segment) {
        segment.setState("hover");
      })
      
      hoveredSeries.legendDataItem.marker.setState("default");
      hoveredSeries.legendDataItem.label.setState("default");

      /*chart.series.each(function(series) {
        if (series != hoveredSeries) {
          series.LineSegments.each(function(segment) {
            segment.setState("dimmed");
          })
          series.bulletsContainer.setState("dimmed");
          series.legendDataItem.marker.setState("dimmed");
          series.legendDataItem.label.setState("dimmed");
        }
      });*/
    }

    function processOut(ev) {
      chart.series.each(function(series) {
        /*series.segments.each(function(segment) {
          segment.setState("default");
        })*/
        series.bulletsContainer.setState("default");
        series.legendDataItem.marker.setState("default");
        series.legendDataItem.label.setState("default");
      });
    }


    /*/ Add simple bullet
    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.href = "https://www.amcharts.com/lib/images/star.svg";
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";*/

  }

  async getTableData() {
    if (this.selectedSocial == [] || this.selectedAccount == [] || this.dateFrom == undefined || this.dateTo == undefined) {
      return;
    }
    for (let index = 0; index < this.selectedSocial.length; index++) {
      if (this.selectedSocial[index] == 1) {

        let tempProfileIds = this.selectedAccount.map(t=>t.idQFB);
        let profileIds: any = [];
        tempProfileIds.forEach(element => {
          if (element != 0) {
            profileIds.push(element);
          }
        });

        let data = {
          dateFrom : this.dateFrom.year + '-' + this.dateFrom.month + '-' + this.dateFrom.day,
          dateTo : this.dateTo.year + '-' + this.dateTo.month + '-' + this.dateTo.day,
          profileIds : profileIds.toString()
        };

      if (profileIds.length > 0){
        
        await this.tableDataService.getFacebookIntTime(data).subscribe((res:any)=>{
          if (JSON.parse(res).success == false) {
            return;
          }
          console.log(JSON.parse(res).data);
          
          this.drawFBChart(JSON.parse(res).data)


        });

        await this.tableDataService.getFacebookFansTime(data).subscribe((res:any)=>{
          if (JSON.parse(res).success == false) {
            return;
          }
          console.log(JSON.parse(res).data);
          
          this.drawFBFanChart(JSON.parse(res).data)


        });
        
        await this.tableDataService.getFaceBookData(data).subscribe((res:any)=>{
            if (JSON.parse(res).success == false) {
              return;
            }

            let tempFacebookData = JSON.parse(res).data;
            let t: any = {};
            
            tempFacebookData.forEach(element => {
              t[element.ProfileId] = element;
            });

            this.facebookData = [];
            
            let tt = {'Average interactions per post': 0,
              Comments: '',
              Fans: '',
              Growth: '',
              Interactions:'',
              ProfileId: '',
              Publications: '',
              Reactions: '',
              Shares: ''
            }
            
            this.selectedAccount.forEach(element => {
              this.facebookData.push((t[element.idQFB]? t[element.idQFB]: tt))
            });
          },
          err => {
          }
        );}
        
      } else if (this.selectedSocial[index] == 2) {
        let tempProfileIds = this.selectedAccount.map(t=>t.idQTW);
        let profileIds: any = [];
        tempProfileIds.forEach(element => {
          if (element != 0) {
            profileIds.push(element);
          }
        });

        let data = {
          dateFrom : this.dateFrom.year + '-' + this.dateFrom.month + '-' + this.dateFrom.day,
          dateTo : this.dateTo.year + '-' + this.dateTo.month + '-' + this.dateTo.day,
          profileIds : profileIds.toString()
        };

        if (profileIds.length > 0){
        /*await this.tableDataService.getTwitterIntTime(data).subscribe((res:any)=>{
          if (JSON.parse(res).success == false) {
            return;
          }
          console.log(JSON.parse(res).data);
          
          this.drawTWChart(JSON.parse(res).data)


        });*/

        /*await this.tableDataService.getTwitterFansTime(data).subscribe((res:any)=>{
          if (JSON.parse(res).success == false) {
            return;
          }
          console.log(JSON.parse(res).data);
          
          this.drawTWFanChart(JSON.parse(res).data)


        });*/
          this.twitterData = [];
          let tempTwitterData = [];
          let t: any = {};
          await profileIds.forEach(profileID => {
            data.profileIds = profileID;
            this.tableDataService.getTwitterData(data).subscribe((res:any)=>{
              if (JSON.parse(res).success == false) {
                return;
              }
            
              
              tempTwitterData.push(JSON.parse(res));
              

              console.log("tempTikTokData: ",tempTwitterData)
              tempTwitterData.forEach(element => {
                t[element.metadata.profile_id] = element.data;
              });

              
              
              this.twitterData = [];
              console.log("T: ",t)
              console.log("selectedAccount",this.selectedAccount)
              let tt = {
                profile_followers:{value: ''},
                profile_followers_growth_absolute_per_day:{value: ''},
                profile_tweets:{value:''},
                profile_retweets:{value: ''},
                profile_favorites:{value: ''},
                tweets_replies:{value: ''},
                tweets_interaction:{value: ''},
                tweets_engagement:{value: ''},

              }

              this.selectedAccount.forEach(element => {
                this.twitterData.push(t[element.idQTW]? t[element.idQTW]: tt)
              });
              console.log("TwitterData",this.twitterData)

            },err => {});
          });

          
          
        }
        


      } else if (this.selectedSocial[index] == 5) {
        let tempProfileIds = this.selectedAccount.map(t=>t.idQTK);
        let profileIds: any = [];
        tempProfileIds.forEach(element => {
          if (element != 0) {
            profileIds.push(element);
          }
        });

        let data = {
          dateFrom : this.dateFrom.year + '-' + this.dateFrom.month + '-' + this.dateFrom.day,
          dateTo : this.dateTo.year + '-' + this.dateTo.month + '-' + this.dateTo.day,
          profileIds : profileIds/*.toString()*/
        };

        if (profileIds.length > 0){
          this.TikTokData = [];
          let tempTikTokData = [];
          let t: any = {};
          await profileIds.forEach(profileID => {
            data.profileIds = profileID;
            this.tableDataService.getTikTokData(data).subscribe((res:any)=>{
              if (JSON.parse(res).success == false) {
                return;
              }
            
              
              tempTikTokData.push(JSON.parse(res));
              

              console.log("tempTikTokData: ",tempTikTokData)
              tempTikTokData.forEach(element => {
                t[element.metadata.profile_id] = element.data;
              });

              
              
              this.TikTokData = [];
              console.log("T: ",t)
              console.log("selectedAccount",this.selectedAccount)
              let tt = {
                tiktoker_profile_follower_count:{value: ''},
                tiktoker_followers_growth_absolute_per_day:{value: ''},
                tiktoker_video_share_like_comment_count:{value:''},
                tiktoker_video_count:{value: ''},
                tiktoker_plays_per_day:{value: ''}
              }

              this.selectedAccount.forEach(element => {
                this.TikTokData.push(t[element.idQTK]? t[element.idQTK]: tt)
                
              });
              console.log("TikTokData",this.TikTokData)

            },err => {});
          });

          
          
        }
        


      } else if (this.selectedSocial[index] == 3) {
        let tempProfileIds = this.selectedAccount.map(t=>t.idQIG);
        let profileIds: any = [];
        tempProfileIds.forEach(element => {
          if (element != 0) {
            profileIds.push(element);
          }
        });

        let data = {
          dateFrom : this.dateFrom.year + '-' + this.dateFrom.month + '-' + this.dateFrom.day,
          dateTo : this.dateTo.year + '-' + this.dateTo.month + '-' + this.dateTo.day,
          profileIds : profileIds/*.toString()*/
        };

        if (profileIds.length > 0){
          this.instagramData = [];
          let tempInstagramData = [];
          let t: any = {};
          await profileIds.forEach(profileID => {
            data.profileIds = profileID;
            this.tableDataService.getInstagramData(data).subscribe((res:any)=>{
              if (JSON.parse(res).success == false) {
                return;
              }
            
              
              tempInstagramData.push(JSON.parse(res));
              

              console.log("tempInstagramData: ",tempInstagramData)
              tempInstagramData.forEach(element => {
                t[element.metadata.profile_id] = element.data;
              });

              
              
              this.instagramData = [];
              console.log("T: ",t)
              console.log("selectedAccount",this.selectedAccount)

              let tt = {
                profile_followers:{value: ''},
                profile_followers_growth_absolute_per_day:{value: ''},
                profile_media_count:{value: ''},
                profile_likes_count:{value: ''},
                profile_comments_count:{value: ''},
                profile_media_interaction:{value: ''}
                
            }

              this.selectedAccount.forEach(element => {
                this.instagramData.push(t[element.idQIG] ? t[element.idQIG] : tt)
                
              });
              console.log("InstagramData",this.instagramData)

            },err => {});
          });

          
          
        }
      
        //YOUTUBE-------------------------------------------------------------------  
      } else {
        let tempProfileIds = this.selectedAccount.map(t=>t.idQYT);
        let profileIds: any = [];
        tempProfileIds.forEach(element => {
          if (element != 0) {
            profileIds.push(element);
          }
        });

        let data = {
          dateFrom : this.dateFrom.year + '-' + this.dateFrom.month + '-' + this.dateFrom.day,
          dateTo : this.dateTo.year + '-' + this.dateTo.month + '-' + this.dateTo.day,
          profileIds : profileIds/*.toString()*/
        };

        if (profileIds.length > 0){
          this.youtubeData = [];
          let tempYoutubeData = [];
          let t: any = {};
          await profileIds.forEach(profileID => {
            data.profileIds = profileID;
            this.tableDataService.getYoutubeData(data).subscribe((res:any)=>{
              if (JSON.parse(res).success == false) {
                return;
              }
            
              
              tempYoutubeData.push(JSON.parse(res));
              

              console.log("tempYoutubeData: ",tempYoutubeData)
              tempYoutubeData.forEach(element => {
                t[element.metadata.profile_id] = element.data;
              });

              
              
              this.youtubeData = [];
              console.log("T: ",t)
              console.log("selectedAccount",this.selectedAccount)

              let tt = {
                channel_views_per_video:{value: ''},
                channel_video_view_count:{value: ''},
                channel_videos_count:{value: ''},
                channel_subscribers_growth_absolute_per_day:{value: ''},
                channel_subscribers_count:{value: ''}
            }

              this.selectedAccount.forEach(element => {
                this.youtubeData.push(t[element.idQYT] ? t[element.idQYT] : tt)
                
              });
              console.log("youtubeData",this.youtubeData)

            },err => {});
          });

          
          
        }
      }
    }
    $('.borrar').closest('tr').show();
    $(document).on('click', '.borrar', function(event) {
      event.preventDefault();
      $(this).closest('tr').hide();
    });

  }
}