import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';

import {ThemeOptions} from '../../../theme-options';

@Component({
  selector: 'app-report-type',
  templateUrl: './report-type.component.html',
  styleUrls: ['./report-type.component.css']
})
export class ReportTypeComponent implements OnInit {
  heading = 'Tipo de reporte';
  private projectName: string;
  private imageUrl: string = '';
  private projectId: number;
  constructor(public globals: ThemeOptions, private route: ActivatedRoute, private router:Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];
      this.imageUrl = params['imageUrl'];
    });
    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }
  }
  
  showReport(reportType) {
    let params ={
      projectId: this.projectId,
      projectName: this.projectName,
      imageUrl: this.imageUrl
    }
    console.log(reportType)
    if (reportType == 'capasci') {
      this.router.navigate(['/main/capiscireport'], { queryParams: params});
    } else if (reportType == 'cuantitativo') {
      this.router.navigate(['/main/cuantitativoreport'], { queryParams: params});
    } else {
      this.router.navigate(['/main/pautareport'], { queryParams: params});
    }
  }
}
