import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";

am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-area-dataviz',
  templateUrl: './area-dataviz.component.html',
  styleUrls: ['./area-dataviz.component.css']
})
export class AreaDataVizComponent implements OnInit {
  @Input() infotype;
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartAreaDataviz') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }
  
  ngOnChanges () {
    let chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
    chart.colors.list = [
      am4core.color("#2E489C"),
      am4core.color("#950000"),
      am4core.color("#313131"),
    ];

    let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type: 'bar_dataviz',
      valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }

    this.chartService
      .getAreaDatabizData(data)
      .subscribe((res:any)=>{
        chart.data = res;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.title.text = "Fecha";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.rotation = -90;
        
        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0.5;
        
        
        let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //valueAxis.title.text = "Porcentaje";
        valueAxis.calculateTotals = true;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minWidth = 50;
        valueAxis.renderer.labels.template.adapter.add("text", function(text) {
          return text + "%";
        });

        
        
        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.exporting.menu = new am4core.ExportMenu();
        
        series.calculatePercent = true;
        series.dataFields.valueY = "positive";
        series.dataFields.valueYShow = "totalPercent";
        series.dataFields.categoryX = "date";
        series.name = "Positive";
        
        series.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Positivo {valueY.totalPercent.formatNumber('#.0')}%</b></span>";
        
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#FFF");
        
        series.tooltip.getStrokeFromObject = true;
        series.tooltip.background.strokeWidth = 3;
        
        series.fillOpacity = 0.85;
        series.stacked = true;
        
        // static
        series.legendSettings.labelText = "Positivo";
        
        // hovering
        series.legendSettings.itemLabelText = "Positivo";
        
        let series2 = chart.series.push(new am4charts.LineSeries());
        
        series2.calculatePercent = true;
        series2.dataFields.valueY = "negative";
        series2.dataFields.valueYShow = "totalPercent";
        series2.dataFields.categoryX = "date";
        series2.name = "Negative";
        
        series2.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Negativo {valueY.totalPercent.formatNumber('#.0')}%</b></span>";
        
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#FFF");
        
        series2.tooltip.getStrokeFromObject = true;
        series2.tooltip.background.strokeWidth = 3;
        
        series2.fillOpacity = 0.85;
        series2.stacked = true;
        
        // static
        series2.legendSettings.labelText = "Negativo";
        
        // hovering
        series2.legendSettings.itemLabelText = "Negativo";
        
        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.calculatePercent = true;
        series3.dataFields.valueY = "neutral";
        series3.dataFields.valueYShow = "totalPercent";
        series3.dataFields.categoryX = "date";
        series3.name = "Neutral";
        series3.tooltipText = "{name}: [bold]{valueY}[/]";
        
        series3.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Neutral {valueY.totalPercent.formatNumber('#.0')}%</b></span>";
        
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4core.color("#FFF");
        
        series3.tooltip.getStrokeFromObject = true;
        series3.tooltip.background.strokeWidth = 3;
        
        series3.fillOpacity = 0.85;
        series3.stacked = true;
        
        // static
        series3.legendSettings.labelText = "Neutral";
        
        // hovering
        series3.legendSettings.itemLabelText = "Neutral";
        
        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        
        // add legend
        chart.legend = new am4charts.Legend();
      },
      err => {
      }
    );
  }
}
