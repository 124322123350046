import {Component, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';


@Component({
  selector: 'app-desglose-gastos',
  templateUrl: './desglose-gastos.component.html',
  styleUrls: ['./desglose-gastos.component.css']
})
export class DesgloseGastosComponent {
  heading = 'Pauta';
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;

  private desglosePlataformaData: any = [];
  private desgloseTotalData: any = [];


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.getTableData();
  }

  getTableData() {
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      projectId : this.projectId
    };

    this.pautaService
      .getDesglosePlataformaData(data)
      .subscribe((res:any)=>{
        let tempKey: number = 0;
        let tempSpan: any = [];
        let tempStr: string = "";
        res.forEach(element => {
          if (tempStr == element.OrigenPresupuesto) {
            tempSpan[(tempKey - 1)] += 1;
          } else {
            tempStr = element.OrigenPresupuesto;
            tempKey += 1;
            tempSpan[(tempKey - 1)] = 1;
          }
        });

        tempKey = 0;
        tempStr = "";
        res.forEach(element => {
          if (tempStr == element.OrigenPresupuesto) {
            element.rowSpan = 0;
          } else {
            tempKey += 1;
            tempStr = element.OrigenPresupuesto;
            element.rowSpan = tempSpan[(tempKey - 1)];
          }
          this.desglosePlataformaData.push(element);
        });
      },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getDesgloseTotalData(data)
      .subscribe((res:any)=>{
        // this.desglosePlataformaData = res;
        console.log(res)
        res.forEach(element => {
          this.desgloseTotalData.push({origenpresupuesto: element.OrigenPresupuesto, key: "Comisión", value: element.comision, rowSpan:3})
          this.desgloseTotalData.push({origenpresupuesto: element.OrigenPresupuesto, key: "IVA", value: element.iva, rowSpan:0})
          this.desgloseTotalData.push({origenpresupuesto: element.OrigenPresupuesto, key: "Total", value: element.total, rowSpan:0})
        });
        console.log(this.desgloseTotalData)
      },
      err => {
        console.log(err)
      }
    );
    
  }
}
