import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';

am4core.useTheme(am4themes_animated);

function shuffleArray(arr) {
  arr.sort(() => Math.random() - 0.5);
}

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
        am4core.color("#94D40B"),
        am4core.color("#FA4516"),
        am4core.color("#42C7E6"),
        am4core.color("#0056B9"),
        am4core.color("#F4B220"),
        am4core.color("#85329A"),
        am4core.color("#490E68")
    ];
    shuffleArray(target.list);
  }
}



am4core.useTheme(am4themes_myTheme);

@Component({
  selector: 'app-gestor-pauta',
  templateUrl: './gestor-pauta.component.html',
  styleUrls: ['./gestor-pauta.component.css']
})
export class GestorPautaComponent {
  heading = 'Pauta';
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;
  @ViewChild('amchartPlataforma') chartPlataforma: ElementRef<HTMLElement>;
  @ViewChild('amchartEstrategia') chartEstrategia: ElementRef<HTMLElement>;
  @ViewChild('amchartPautas') chartPautas: ElementRef<HTMLElement>;

  private gastoPlataformaData: any = [];
  private gastoPlataformaChartData: any = [];
  private gastoEstrategiaData: any = [];
  private gastoPautasData: any = [];


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.filterType = this.filterType.split(',');
    this.getData();
  }

  getData() {
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      projectId : this.projectId
    };

    this.pautaService
      .getGastoPlataformaData(data)
      .subscribe((res:any)=>{
        this.gastoPlataformaData = res[0];
      },
      err => {
        console.log(err)
      }
    );
      //Gasto por plataforma
    this.pautaService

      .getDesglosePlataformaData(data)
      .subscribe((res:any)=>{
        console.log(res)
        res.forEach(element => {
          this.gastoPlataformaChartData.push({
            key: element.Plataforma, 
            value: element.gasto,
            color: element.Plataforma == "Facebook" ? '#4267B2' : element.Plataforma == "Instagram" ? "#833AB4" :  element.Plataforma == "Youtube" ? "#FF0000": "#E50914"
           });
        });

        this.drawChart(this.gastoPlataformaChartData, 0);
      },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getGastoEstrategiaData(data)
      .subscribe((res:any)=>{
        res.forEach(element => {
          this.gastoEstrategiaData.push({key: element.Estrategia, value: element.gasto});
        });

        this.drawChart(this.gastoEstrategiaData, 1);
      },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getGastoPautasData(data)
      .subscribe((res:any)=>{
        res.forEach(element => {
          this.gastoPautasData.push({key: element.Estrategia, value: element.total});
        });
        
        this.drawChart(this.gastoPautasData, 2);
      },
      err => {
        console.log(err)
      }
    );
  }

  drawChart(data, flag) {
    let chart: any;
    let pieSeries:any;

   
    
    if (flag == 0) {
      chart = am4core.create(this.chartPlataforma.nativeElement, am4charts.PieChart);
      pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "key";
      pieSeries.slices.template.tooltipText = "{key}: {value.percent.formatNumber('#.0')}%";
      pieSeries.slices.template.propertyFields.fill = "color";
      chart.legend = new am4charts.Legend();
      chart.legend.labels.template.text = "{key}";
      
    } else if (flag == 1) {
      chart = am4core.create(this.chartEstrategia.nativeElement, am4charts.PieChart);
      pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "key";
      pieSeries.slices.template.tooltipText = "{key}: {value.percent.formatNumber('#.0')}%";
      
      chart.legend = new am4charts.Legend();
      chart.legend.labels.template.text = "{key}";
      
      
    } else {
      chart = am4core.create(this.chartPautas.nativeElement, am4charts.PieChart);
      pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "key";
      pieSeries.slices.template.tooltipText = "{key}: {value}";//.percent.formatNumber('#.0')}%";
      
      chart.legend = new am4charts.Legend();
      //chart.legend.labels.template.text =  "{key: {value.value}";//.percent.formatNumber('#.0')}%";
      chart.legend.labels.template.text = "{key}";
      chart.legend.valueLabels.template.text = "{value}";
    }

    chart.exporting.menu = new am4core.ExportMenu();
		chart.data = data;
    
    //let label = chart.seriesContainer.createChild(am4core.Label);
      
    chart.data.sort((a, b) => a.value > b.value ? 1 : -1);
    
      
    // Add and configure Series
    //let pieSeries = chart.series.push(new am4charts.PieSeries());
    //pieSeries.dataFields.value = "value";
    //pieSeries.dataFields.category = "key";
    let list = [];
      
    pieSeries.labels.template.fill = am4core.color("white");
    /*pieSeries.colors.list = [
      am4core.color("green"),
      am4core.color("#313131"),
      am4core.color("red"),
      am4core.color("blue"),
      am4core.color("gray"),
      am4core.color("yellow"),
    ];*/
    
    //pieSeries.slices.template.tooltipText = "{key}: {value.percent.formatNumber('#.0')}%";
    pieSeries.slices.template.tooltipX = am4core.percent(100);
    pieSeries.slices.template.tooltipY = am4core.percent(0);
    

    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;
    //chart.legend = new am4charts.Legend();
    //chart.legend.labels.template.text = "{name}: {value}%";
    chart.legend.position = "bottom";
    
    
    let watermark = new am4core.Image();
    watermark.href = "./assets/images/wm-capisci.png";
    chart.chartContainer.children.push(watermark);
    watermark.align = "center";
    watermark.valign = "bottom";
    watermark.opacity = 0.2;
    
    watermark.width = 100;
    watermark.height = 50;

  }
}
