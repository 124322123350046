import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';

am4core.useTheme(am4themes_animated);

function shuffleArray(arr) {
  arr.sort(() => Math.random() - 0.5);
}

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
        am4core.color("#94D40B"),
        am4core.color("#FA4516"),
        am4core.color("#42C7E6"),
        am4core.color("#0056B9"),
        am4core.color("#F4B220"),
        am4core.color("#85329A"),
        am4core.color("#490E68")
    ];
    shuffleArray(target.list);
  }
}

am4core.useTheme(am4themes_myTheme);

@Component({
  selector: 'app-resumen-gastos',
  templateUrl: './resumen-gastos.component.html',
  styleUrls: ['./resumen-gastos.component.css']
})
export class ResumenGastosComponent {
  heading = 'Pauta';
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;
  @ViewChild('amchartEstrategia') chartEstrategia: ElementRef<HTMLElement>;
  @ViewChild('amchartPlataforma') chartPlataforma: ElementRef<HTMLElement>;

  private resumenGastosModeloData: any = {};
  private estrategiaData: any = {};
  private estrategiaTotalData: any = {};
  private plataformaTotalData: any = {};


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.filterType = this.filterType.split(',');
    // console.log(this.filterType)
    this.getTableData();
  }

  getTableData() {
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      projectId : this.projectId
    };

    this.pautaService
      .getResumenGastosModeloData(data)
      .subscribe((res:any)=>{
        console.log("LA RES ", res)
        res.forEach(element => {
          this.resumenGastosModeloData[element.OrigenPresupuesto] = element;
        });
      },
      err => {
        console.log(err)
      }
    );
    
    this.pautaService
      .getEstrategiaData(data)
      .subscribe((res:any)=>{
        res.forEach(element => {
          this.estrategiaData[element.Estrategia] = element.num;
        });
      },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getEstrategiaTotalData(data)
      .subscribe((res:any)=>{
        let sumGasto: number = 0;
        res.forEach(element => {
          sumGasto += element.gasto * 1;
        });
        res.forEach(element => {
          this.estrategiaTotalData[element.Estrategia] = Math.round(element.gasto/sumGasto * 10000) / 100;
        });
        this.drawAmchart(this.estrategiaTotalData, 0);
      },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getPlataformaTotalData(data)
      .subscribe((res:any)=>{
        let sumTotal: number = 0;
        res.forEach(element => {
          sumTotal += element.total * 1;
        });
        res.forEach(element => {
          this.plataformaTotalData[element.Plataforma] = Math.round(element.total/sumTotal * 10000) / 100;
        });
        this.drawAmchart(this.plataformaTotalData, 1);
      },
      err => {
        console.log(err)
      }
    );
  }

  drawAmchart(data, flag) {
    function processData(data) {

      let treeData = [];
      for (var brand in data) {
        let brandData = { 
          name: brand, 
          count: data[brand],
          color: brand == "Facebook" ? '#4267B2' : brand == "Instagram" ? "#833AB4" :  brand == "Youtube" ? "#FF0000": "#E50914"
        }
        treeData.push(brandData);
      }
      return treeData;
    }

    // create chart
    let chart;
    if (flag == 0) {
        chart = am4core.create(this.chartEstrategia.nativeElement, am4charts.TreeMap);
    } else {
      chart = am4core.create(this.chartPlataforma.nativeElement, am4charts.TreeMap);
    }
    
    chart.exporting.menu = new am4core.ExportMenu();
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    // only one level visible initially
    chart.maxLevels = 1;
    // define data fields
    chart.dataFields.value = "count";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";

    if (flag == 0) {
      chart.homeText = "Gasto por estrategia";
    } else {
      chart.homeText = "Gasto por plataforma";
      
    }

    // enable navigation
    chart.navigationBar = new am4charts.NavigationBar();
    

    // level 0 series template
    let level0SeriesTemplate = chart.seriesTemplates.create("0");
    level0SeriesTemplate.strokeWidth = 2;
    
  

    // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
    // create hover state
    let columnTemplate = level0SeriesTemplate.columns.template;
    columnTemplate.tooltipText= "{name}: {count.formatNumber('#.0')}%";
    let hoverState = columnTemplate.states.create("hover");

    // darken
    hoverState.adapter.add("fill", function (fill, target) {
      if (fill instanceof am4core.Color) {
        return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
      }
      return fill;
    })

    let bullet0 = level0SeriesTemplate.bullets.push(new am4charts.LabelBullet());
    bullet0.locationX = 0.5;
    bullet0.locationY = 0.5;
    bullet0.label.text = "{name}: {count.formatNumber('#.0')}%";
    bullet0.label.fill = am4core.color("#ffffff");
    if (flag != 0) {
      //bullet0.series.template.propertyFields.fill = "color";
      chart.dataFields.color = "color";
      //chart.homeText = "Gasto por estrategia";
    } 
    //

    // level1 series template
    let level1SeriesTemplate = chart.seriesTemplates.create("1");
    level1SeriesTemplate.columns.template.fillOpacity = 0;

    let bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
    bullet1.locationX = 0.5;
    bullet1.locationY = 0.5;

    bullet1.label.text = "{name}: {count.formatNumber('#.0')}%";
    bullet1.label.fill = am4core.color("#ffffff");

    chart.data = processData(data);
  }
}
