import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {FlexLayoutModule} from "@angular/flex-layout";
import {DataTablesModule} from 'angular-datatables';

import { NgSelectModule } from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

// BOOTSTRAP COMPONENTS

import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';

// LAYOUT

import {BaseLayoutComponent} from './components/layout/base-layout/base-layout.component';


import {HeaderComponent} from './components/layout/header/header.component';
import {PageTitleComponent} from './components/layout/page-title/page-title.component';
import {SidebarComponent} from './components/layout/sidebar/sidebar.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {SearchBoxComponent} from './components/layout/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './components/layout/header/elements/user-box/user-box.component';



import { CapisciReportComponent } from './components/main/capisci-report/capisci-report.component';
import { CuantitativoReportComponent } from './components/main/cuantitativo-report/cuantitativo-report.component';
import { ProjectComponent } from './components/main/project/project.component';
import { ShowChartComponent } from './components/main/showchart/showchart.component';
import { ReportTypeComponent } from './components/main/report-type/report-type.component';
import { AddUserComponent } from './components/main/add-user/add-user.component';
import { ProjectAssignComponent } from './components/main/project-assign/project-assign.component';
import { PautaReportComponent } from './components/main/pauta-report/pauta-report.component';
import { ShowPautaComponent } from './components/main/show-pauta/show-pauta.component';

//armchart
import { AreaDataVizComponent } from './components/main/showchart/amchart/area-dataviz/area-dataviz.component';
import { GaugeWithBandsComponent } from './components/main/showchart/amchart/gauge-with-bands/gauge-with-bands.component';
import { FrozenComponent } from './components/main/showchart/amchart/frozen/frozen.component';
import { BarDataVizComponent } from './components/main/showchart/amchart/bar-dataviz/bar-dataviz.component';
import { ProgressChartComponent } from './components/main/showchart/amchart/progress-chart/progress-chart.component';
import { SpiritedAwayComponent } from './components/main/showchart/amchart/spiritedaway/spiritedaway.component';
import { SimpleColumnComponent } from './components/main/showchart/amchart/simple-column/simple-column.component';
import { MovingBulletsComponent } from './components/main/showchart/amchart/moving-bullets/moving-bullets.component';

//showpauta
import { ResumenGastosComponent } from './components/main/show-pauta/showdata/resumen-gastos/resumen-gastos.component';
import { DesgloseGastosComponent } from './components/main/show-pauta/showdata/desglose-gastos/desglose-gastos.component';
import { GestorPautaComponent } from './components/main/show-pauta/showdata/gestor-pauta/gestor-pauta.component';
import { ResumenObjetivosComponent } from './components/main/show-pauta/showdata/resumen-objetivos/resumen-objetivos.component';
import { HistoricoCprComponent } from './components/main/show-pauta/showdata/historico-cpr/historico-cpr.component';
import { ControlCampanasComponent } from './components/main/show-pauta/showdata/control-campanas/control-campanas.component';
import { DesempenoReporduccionesComponent } from './components/main/show-pauta/showdata/desempeno-reporducciones/desempeno-reporducciones.component';


// Pages

import {UserAuth} from './components/user/userauth';
import {ForgotComponent} from './components/user/forgot/forgot.component';
import {LoginComponent} from './components/user/login/login.component';
import {RegisterComponent} from './components/user/register/register.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    AppComponent,
    
    BaseLayoutComponent,
    
    
    HeaderComponent,
    PageTitleComponent,
    SidebarComponent,
    FooterComponent,
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    
    ForgotComponent,
    LoginComponent,
    ReportTypeComponent,
    RegisterComponent,
    CapisciReportComponent,
    CuantitativoReportComponent,
    ProjectComponent,
    ShowChartComponent,
    AddUserComponent,
    ProjectAssignComponent,
    PautaReportComponent,
    ShowPautaComponent,

    ResumenGastosComponent,
    DesgloseGastosComponent,
    GestorPautaComponent,
    ResumenObjetivosComponent,
    HistoricoCprComponent,
    ControlCampanasComponent,
    DesempenoReporduccionesComponent,


    AreaDataVizComponent,
    GaugeWithBandsComponent,
    FrozenComponent,
    BarDataVizComponent,
    ProgressChartComponent,
    SpiritedAwayComponent,
    SimpleColumnComponent,
    MovingBulletsComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    FlexLayoutModule,
    DataTablesModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    // Charts

    ChartsModule,
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ConfigActions,
    UserAuth,
    
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
