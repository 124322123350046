import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-frozen',
  templateUrl: './frozen.component.html',
  styleUrls: ['./frozen.component.css']
})
export class FrozenComponent implements OnInit {
  @Input() infotype;
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() type;
  @Input() type1;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartFrozen') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

	ngOnInit(){
    
  }

  ngOnChanges() {
    let chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
    chart.exporting.menu = new am4core.ExportMenu();

		chart.data = [];

		let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type: this.type,
      type1: this.type1,
      valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }

		this.chartService
      .getFrozenData(data)
      .subscribe((res:any)=>{
				chart.data = res;
				// Add label
				let label = chart.seriesContainer.createChild(am4core.Label);
        if (this.type == "sexo") {
          chart.data.sort((a, b) => a.sector > b.sector ? 1 : -1);
        }
				
				// Add and configure Series
				let pieSeries = chart.series.push(new am4charts.PieSeries());
				pieSeries.dataFields.value = "size";
				pieSeries.dataFields.category = "sector";
        let list = [];
        if (this.type == "sexo") {
          
          console.log(JSON.stringify(chart.data));
          try {
            var indexNum = chart.data.findIndex(obj => obj.sector.match("Femenino"));
            list.push(am4core.color("pink"))
            chart.data[indexNum]["icon"] = "fa-female";
          } catch{
            console.log("NO FEMALES")
          }
          try {
            var indexNum = chart.data.findIndex(obj => obj.sector.match("Masculino"));
            list.push(am4core.color("#2E489C"))
            chart.data[indexNum]["icon"] = "fa-male";
          } catch{
            console.log("NO MALES")
          }

          pieSeries.labels.template.html = '<i class="fas {icon}"></i>';
          pieSeries.labels.template.fill = am4core.color("white");
          pieSeries.colors.list = list;
        } else if (this.type == "userType") {
          pieSeries.colors.list = [
            am4core.color("green"),
            am4core.color("#313131"),
          ];
        } else if (this.type == "socioeconomicLevel"){
          pieSeries.heatRules.push({ target: pieSeries.slices.template, property: "fill", dataField: "value", min: am4core.color("#FFDC73"), max: am4core.color("#A67C00") });
        }
          
          pieSeries.slices.template.tooltipText = "{sector}: {value.percent.formatNumber('#.0')}%";
          pieSeries.slices.template.tooltipX = am4core.percent(100);
          pieSeries.slices.template.tooltipY = am4core.percent(0);
          

          pieSeries.ticks.template.disabled = true;
          pieSeries.labels.template.disabled = true;
        if(this.type == 'conversation') {
          chart.legend = new am4charts.Legend();
          chart.legend.position = "right";
        } else {
          chart.legend = new am4charts.Legend();
          chart.legend.position = "bottom";
        }
        let watermark = new am4core.Image();
        watermark.href = "./assets/images/wm-capisci.png";
        chart.chartContainer.children.push(watermark);
        watermark.align = "center";
        watermark.valign = "bottom";
        watermark.opacity = 0.2;
        watermark.width = 100;
        watermark.height = 50;
      },
      err => {
      }
    );
  }   
}