import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";
import * as decode from 'jwt-decode';

import { ErrorHandlerService } from "./error-handler.service";  

@Injectable({
  providedIn: "root",
})
export class UserService {
  //private url = "http://localhost:3000";
  private url = "http://18.223.121.79:3000";
  

  private _tokenPayload;
  public _userName;
  public _admin;

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ "Content-Type": "application/json" ,'Access-Control-Allow-Origin': '*','Access-Control-Allow-Headers': 'content-type','Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'}),
    
  };

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
    private router: Router
  ) {}

  login(data) {
    return this.http
      .post(this.url+"/user/login", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("login")));
  }

  getUsers(data) {
    return this.http
      .post(this.url+"/user/getUsers", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getUsers")));
  }

  saveUser(data) {
    return this.http
      .post(this.url+"/user/saveUser", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("saveUser")));
  }

  getToken() {
    return localStorage.getItem('Token');
  }
  
  setToken(token: string): void {
    this.decodeToken();
    localStorage.setItem('Token', token);
  }

  decodeToken() {
    if(!this._tokenPayload) {
      const token = localStorage.getItem('Token');
      if(token) {
        const payload = decode(token);
        if(payload) {
          this._tokenPayload =  payload;
          this._userName = payload.userName;
          this._admin = payload.admin;
        }
        return payload;
      }
    }
  }

  deleteToken() {
    localStorage.removeItem('Token');
    this._tokenPayload = null;
  }

  public get uniqueUserName(): any {
    this.decodeToken();
    return this._userName;
  }

  public get uiqueAdmin(): any {
    this.decodeToken();
    return this._admin;
  }

  public get tokenPayload(): any {
    this.decodeToken();
    return this._tokenPayload;
  }

  getAccount(data) {
    return this.http
      .post(this.url+"/user/getAccount", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getAccount")));
  }

}
