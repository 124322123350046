import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './components/layout/base-layout/base-layout.component';


import { ReportTypeComponent } from './components/main/report-type/report-type.component';
import { CapisciReportComponent } from './components/main/capisci-report/capisci-report.component';
import { CuantitativoReportComponent } from './components/main/cuantitativo-report/cuantitativo-report.component';
import { ProjectComponent } from './components/main/project/project.component';
import { ShowChartComponent } from './components/main/showchart/showchart.component';
import { AddUserComponent } from './components/main/add-user/add-user.component';
import { ProjectAssignComponent } from './components/main/project-assign/project-assign.component';
import { PautaReportComponent } from './components/main/pauta-report/pauta-report.component';
import { ShowPautaComponent } from './components/main/show-pauta/show-pauta.component';

// Pages

import {UserAuth} from './components/user/userauth';
import {ForgotComponent} from './components/user/forgot/forgot.component';
import {LoginComponent} from './components/user/login/login.component';
import {RegisterComponent} from './components/user/register/register.component';


const routes: Routes = [
  {path: 'user/login', component: LoginComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
  {path: 'user/register', component: RegisterComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
  {path: 'user/forgot', component: ForgotComponent, data: {extraParameter: ''}},
 
 
  {
    path: 'main',
    component: BaseLayoutComponent,
    children: [
      {path: 'reporttype', component: ReportTypeComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'adduser', component: AddUserComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'projectassign', component: ProjectAssignComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'project', component: ProjectComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'capiscireport', component: CapisciReportComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'pautareport', component: PautaReportComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'showpauta', component: ShowPautaComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'cuantitativoreport', component: CuantitativoReportComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
      {path: 'armchart', component: ShowChartComponent, canActivate: [UserAuth], data: {extraParameter: ''}},
    ]
  },
  {path: '**', redirectTo: 'main/project'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
