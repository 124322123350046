import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { UserService } from "src/app/services/user.service";

import 'rxjs/add/operator/map';

@Component({
  selector: 'app-add-user',
  templateUrl: 'add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnDestroy, OnInit {

  dtOptions: DataTables.Settings = {};
  users: any = [];
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    let data = {}
    
    this.userService
      .getUsers(data)
      .subscribe((res:any)=>{
        this.users = res;
        this.dtTrigger.next();
      },
      err => {
      }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  changeUser(user: any) {
    this.router.navigate(['/user/register'], { queryParams: {userId: user.id} })
  }
}