import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as internal from 'assert';
import swal from 'sweetalert2';

import { UserService } from "src/app/services/user.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  public email:string;
  public userName:string;
  public password:string;
  public confirmPassword:string;
  public accountState:number;
  public buttonString:string;
  private userId:number;


  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
    
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      if (this.userId == undefined) {
        this.userId = 0;
        this.email = '';
        this.userName = '';
        this.password = '';
        this.confirmPassword = '';
        this.accountState = 0;
        this.buttonString = "Create Account";
      } else {
        this.getUserData();
        this.buttonString = "Change Account";
      }
    });
  }

  getUserData() {
    let data = {
      id : this.userId,
    }

    this.userService
      .getUsers(data)
      .subscribe((res:any)=>{
        this.email = res[0].email;
        this.userName = res[0].username;
        this.password = res[0].password;
        this.confirmPassword = res[0].password;
        this.accountState = res[0].admin;
      },
      err => {
      }
    );
  }

  saveAccount() {
    if (!this.email || !this.password || !this.userName || !this.confirmPassword ) {
      swal.fire('please input correctly Account Data.','','warning');
      return;
    }

    if (this.password != this.confirmPassword ) {
      swal.fire('The password is not correct.','','warning');
      return;
    }

    let data = {
      id : this.userId,
      email: this.email,
      password: this.password,
      username: this.userName,
      admin: (this.accountState? 1 : 0)
    }

    this.userService
      .saveUser(data)
      .subscribe((res:any)=>{
        if(res.response === "SUCCESS") {
          swal.fire('Guardado correctamente.','','success');
          this.router.navigate(['/main/adduser']);
        }
        setTimeout(() => {
          if(swal.isVisible()) {
            swal.close();
          }
        },2000);
      },
      err => {
      }
    );
  }
}
