import {Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';

import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-show-pauta',
  templateUrl: './show-pauta.component.html',
  styleUrls: ['./show-pauta.component.css']
})
export class ShowPautaComponent {
  heading = 'Pauta';
  private imageUrl: string = '';
  private projectId: number;
  private cuentaIds: any = [];
  // private cuentaNames: any = [];
  private sectionType: any = [];
  private filterType: any = [];
  private dateFrom: string;
  private dateTo: string;


  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.cuentaIds = params['cuentaIds'];
      // this.cuentaNames = params['cuentaNames'];
      this.heading = params['projectName'];
      this.imageUrl = params['imageUrl'];
      this.sectionType = params['sectionType'];
      this.filterType = params['filterType'];
      this.dateFrom = params['dateFrom'];
      this.dateTo = params['dateTo'];
    });
    console.log(this.cuentaIds)
    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }
  }
}
