import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { UserService } from "src/app/services/user.service";
import { ProjectService } from "src/app/services/project.service";

import 'rxjs/add/operator/map';

@Component({
  selector: 'app-project-assign',
  templateUrl: 'project-assign.component.html',
  styleUrls: ['./project-assign.component.css']
})
export class ProjectAssignComponent implements OnDestroy, OnInit {

  private selectedUserId : number = 0;
  private selectedProjectId : any = [];
  private selectedProjectState: any = [];
  dtOptions: DataTables.Settings = {};
  users: any = [];
  projects: any = [];
  dtUserTrigger: Subject<any> = new Subject<any>();
  dtProjectTrigger: Subject<any> = new Subject<any>();

  constructor(private userService: UserService, private projectService: ProjectService, private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    let userData = {}
    
    this.userService
      .getUsers(userData)
      .subscribe((res:any)=>{
        this.users = res;
        this.dtUserTrigger.next();
      },
      err => {
      }
    );

    let projectData = {}

    this.projectService
      .getProjects(projectData)
      .subscribe((res:any)=>{
        this.projects = res;
        for (let index = 0; index < this.projects.length; index++) {
          this.selectedProjectState.push(false);
        }
        this.dtProjectTrigger.next();
      },
      err => {
      }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtUserTrigger.unsubscribe();
    this.dtProjectTrigger.unsubscribe();
  }

  selectedProject(user: any) {
    this.selectedUserId = user.id;
    let projectData = {
        userId : user.id
    }

    this.projectService
      .getAssignedProject(projectData)
      .subscribe((res:any)=>{
        if (res.length > 0) {
          this.selectedProjectId = res[0]['projectids'].split(',');
        } else {
          this.selectedProjectId = [];
        }
        for (let index = 0; index < this.projects.length; index++) {
          if (this.selectedProjectId.indexOf(this.projects[index]['id']+'') == -1) {
            this.selectedProjectState[index] = false;
          } else {
            this.selectedProjectState[index] = true;
          }
        }
      },
      err => {
      }
    );
  }

  selectProject(proInd: number) {
    if (this.selectedUserId == 0) {
      return true;
    }
    this.selectedProjectState[proInd] = !this.selectedProjectState[proInd];
  }

  saveProjectIds() {
    if (this.selectedUserId == 0) {
      return true;
    }
    let proIds: string = "";
    for (let index = 0; index < this.selectedProjectState.length; index++) {
      if (this.selectedProjectState[index]) {
        if (proIds == "") {
          proIds += this.projects[index]['id'];
        } else {
          proIds += "," + this.projects[index]['id'];
        }
      }
    }

    let data: any = {
      userId: this.selectedUserId,
      proIds: proIds
    }
    
    this.projectService
      .saveProjectIds(data)
      .subscribe((res:any)=>{
        if(res.response === "SUCCESS") {
          swal.fire('Guardado correctamente','','success');
        }
      },
      err => {
      }
    );
  }
}