import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import html2canvas from 'html2canvas';
import swal from 'sweetalert2';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import { ChartService } from "src/app/services/chart.service";
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-showchart',
  templateUrl: './showchart.component.html',
  styleUrls: ['./showchart.component.css']
})
export class ShowChartComponent implements OnInit {
  
  @ViewChild('graphDiv') graphDiv: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  heading = 'Reporte';
  private filterList: any = [
    {name:"VALORACIÓN", state:false, options:[{id:'1. Positiva', name:'Positiva'}, {id:'2. Negativa', name:'Negativa'}, {id:'3. Neutral', name:'Neutral'}]},
    {name:"TEMÁTICA", state:false, options:[]},
    {name:"GÉNERO", state:false, options:[{id:'Masculino', name:'Masculino'}, {id:'Femenino', name:'Femenino'}, {id:'Indefinido', name:'Otro'}]},
    {name:"EDAD", state:false, options:[]},
    {name:"NIVEL SOCIOECONÓMICO", state:false, options:[{id:'1. Alto', name:'Alto'},{id:'2. Medio-Alto', name:'Medio-Alto'},{id:'3. Medio', name:'Medio'},{id:'4. Medio-Bajo', name:'Medio-Bajo'},{id:'5. Bajo', name:'Bajo'},{id:'6. No determinable', name:'No determinable'}]},
    {name:"TIPO DE USUARIO", state:false, options:[{id:'Orgánico', name:'Orgánico'}, {id:'9. Usuario inorgánico ', name:'Inorgánico'}, {id:'No determinable', name:'No determinable'}]},
    {name:"ESTADO", state:false, options:[]},
  ];
  private imageUrl: string = '';
  private minEdad: number;
  private maxEdad: number;
  private projectId: number;
  private infotype: number;
  private section: any = [];
  
  //AQUI MOVIDO
  public dateFrom1: NgbDateStruct;
  public dateTo1: NgbDateStruct;
  //
  private dateFrom: string;
  private dateTo: string;
  private allState: boolean = true;
  private selectedAcc: any = [];
  private compareNum: number;
  private selectedFilterIds = [[],[],[],[],[],[],[]];
  private accountList: any = [];
  private selectedIds: any = [];

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private chartService: ChartService,
      private userService: UserService
    ) {
    this.route.queryParams.subscribe(params => {
      this.heading = params['projectName'];
      this.imageUrl = params['imageUrl'];
      this.projectId = params['projectId'];
      this.infotype = params['infotype'];
      this.section = params['section'];
      this.dateFrom = params['dateFrom'];
      this.dateTo = params['dateTo'];
      this.compareNum = params['compareNum'];
    });

    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }

    let data = {
      
      infotype: this.infotype,
      id: this.projectId,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    }

    this.chartService.getTopicData(data).subscribe((res:any)=>{
        this.filterList[1].options = res;
			},
			err => {
			}
		);

    this.chartService.getEstadoData(data).subscribe((res:any)=>{
        this.filterList[6].options = res;
			},
			err => {
			}
		);
  }

  ngOnInit(){
    let data1 = {
      projectId : this.projectId
    }
    
    this.userService.getAccount(data1).subscribe((res:any)=>{
        this.accountList = res;
      },
      err => {
      }
    );
  }

  changeDate(){
    try {
      let data = {
        heading: this.heading,
        imageUrl:this.imageUrl,
        section:this.section,
        compareNum:this.compareNum,
        infotype: this.infotype,
        projectId: this.projectId,
        dateFrom : this.dateFrom1.year+"-"+this.dateFrom1.month+"-"+this.dateFrom1.day,
        dateTo : this.dateTo1.year+"-"+this.dateTo1.month+"-"+this.dateTo1.day,
      }
      this.router.navigate(['/main/armchart'], { queryParams: data });
    } catch (error) {
      console.log(error)
    }
    

    
  }

  selectFilter(key) {
    this.filterList[key]['state'] = !this.filterList[key]['state'];
  }

  changeEdad() {
    if(this.minEdad && this.minEdad != undefined && this.maxEdad && this.maxEdad != undefined && this.minEdad*1 < this.maxEdad*1) {
      this.selectedFilterIds[3][0] = this.minEdad;
      this.selectedFilterIds[3][1] = this.maxEdad;
    }
  }

  accSelect() {
    if (this.selectedIds != undefined && this.selectedIds.length > this.compareNum) {
      let temp : any = [];
      for (let index = 0; index < this.compareNum; index++) {
        temp.push(this.selectedIds[index]);
      }
      this.selectedIds = temp;
    }

    if (this.selectedIds.length != 0)
      this.selectedAcc = [];
      for (let index = 0; index < this.accountList.length; index++) {
        if(this.selectedIds.indexOf(this.accountList[index]['id']) != -1) {
          this.selectedAcc.push(this.accountList[index]);
        }
      }
  }

  setClass() {
    let strClass: any = {}
    if (this.selectedIds.length != 0)
      if (this.selectedIds.length == 1) {
        strClass['col-md-12'] = true;
      } else if (this.selectedIds.length == 2) {
        strClass['col-md-6'] = true;
      } else if (this.selectedIds.length == 3 || this.selectedIds.length == 4) {
        strClass['col-md-4'] = true;
      }
    return strClass;
  }

  showMultiDiv() {
    if (this.selectedIds.length > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  public downloadAsImage() {
    html2canvas(this.graphDiv.nativeElement, {
    }).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.heading + '.png';
      this.downloadLink.nativeElement.click();
    });
     
  }
}
