import {Component, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';


@Component({
  selector: 'app-historico-cpr',
  templateUrl: './historico-cpr.component.html',
  styleUrls: ['./historico-cpr.component.css']
})
export class HistoricoCprComponent {
  heading = 'Pauta';
  @Input() cuentaId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;

  public cuentaName: string;
  private historicoCprData: any = [];


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.pautaService
    .getCuentaNameById({cuentaId: this.cuentaId})
    .subscribe((res:any)=>{
      this.cuentaName = res[0].name;
    });
    
    this.getTableData();
  }

  getTableData() {
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      cuentaId : this.cuentaId
    };

    this.pautaService
      .getHistoricoCprData(data)
      .subscribe((res:any)=>{
        let tempKey: number = 0;
        let tempSpan: any = [];
        let tempStr: string = "";
        res.forEach(element => {
          if (tempStr == element.plataforma) {
            tempSpan[(tempKey - 1)] += 1;
          } else {
            tempStr = element.plataforma;
            tempKey += 1;
            tempSpan[(tempKey - 1)] = 1;
          }
        });

        tempKey = 0;
        tempStr = "";
        res.forEach(element => {
          if (tempStr == element.plataforma) {
            element.rowSpan = 0;
          } else {
            tempKey += 1;
            tempStr = element.plataforma;
            element.rowSpan = tempSpan[(tempKey - 1)];
          }
          this.historicoCprData.push(element);
        });
      },
      err => {
        console.log(err)
      }
    );
    
  }
}
