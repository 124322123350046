import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { UserService } from "../../../../../services/user.service";
import { PautaService } from "../../../../../services/pauta.service";

@Component({
  selector: "app-control-campanas",
  templateUrl: "./control-campanas.component.html",
  styleUrls: ["./control-campanas.component.css"],
})
export class ControlCampanasComponent {
  heading = "Pauta";
  @Input() cuentaId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;

  public cuentaName: string;
  private controlCampanasData: any = [];

  constructor(
    private userService: UserService,
    private pautaService: PautaService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.pautaService
    .getCuentaNameById({cuentaId: this.cuentaId})
    .subscribe((res:any)=>{
      this.cuentaName = res[0].name;
    });
    
    this.getTableData();
  }

  getTableData() {
    let data = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      cuentaId: this.cuentaId,
    };

    this.pautaService.getControlCampanasData(data).subscribe(
      (res: any) => {
        res.forEach((element) => {
          let tempStartDate = new Date(element.fechainicio);
          let tempEndDate = new Date(element.fechafin);
          element.startDate = `${tempStartDate.getFullYear()}-${
            tempStartDate.getMonth() * 1 + 1
          }-${tempStartDate.getDate()}`;
          element.endDate = `${tempEndDate.getFullYear()}-${
            tempEndDate.getMonth() * 1 + 1
          }-${tempEndDate.getDate()}`;
        });
        this.controlCampanasData = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
