import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

import { ErrorHandlerService } from "./error-handler.service";  

@Injectable({
  providedIn: "root",
})
export class ChartService {
  //private url = "http://localhost:3000";
  private url = "http://18.223.121.79:3000";

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
    private router: Router
  ) {}

  getTopicData(data) {
    return this.http
      .post(this.url+"/chart/getTopicData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getTopicData")));
  }

  getEstadoData(data) {
    return this.http
      .post(this.url+"/chart/getEstadoData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getEstadoData")));
  }

  getFrozenData(data) {
    return this.http
      .post(this.url+"/chart/getFrozenData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getFrozenData")));
  }

  getBarDatabizData(data) {
    return this.http
      .post(this.url+"/chart/getBarDatabizData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getBarDatabizData")));
  }

  getAreaDatabizData(data) {
    return this.http
      .post(this.url+"/chart/getAreaDatabizData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getAreaDatabizData")));
  }

  getSimpleColumnData(data) {
    return this.http
      .post(this.url+"/chart/getSimpleColumnData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getSimpleColumnData")));
  }

  getProgressChartData(data) {
    return this.http
      .post(this.url+"/chart/getProgressChartData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getProgressChartData")));
  }

  getSpiritedawayData(data) {
    return this.http
      .post(this.url+"/chart/getSpiritedawayData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getSpiritedawayData")));
  }

  getMovingBulletsData(data) {
    return this.http
      .post(this.url+"/chart/getMovingBulletsData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getMovingBulletsData")));
  }

  getGaugeData(data) {
    return this.http
      .post(this.url+"/chart/getGaugeData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getGaugeData")));
  }
}
