import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from "src/app/services/user.service";
import { ProjectService } from "src/app/services/project.service";
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  heading = 'Mis Proyectos';
  private payload: any;
  public projects: any = [];

  constructor(private userService: UserService, private projectService: ProjectService, private router: Router) { }

  ngOnInit() {
    this.payload = this.userService.tokenPayload;
    
    let assignedProjectIds:string;

    let data = {
      userId : this.payload.userId
    }
    this.projectService
      .getAssignedProject(data)
      .subscribe((res:any)=>{
        assignedProjectIds = res[0].projectids;
        
        let data1 = {
          projectids : assignedProjectIds
        }

        this.projectService
          .getProjects(data1)
          .subscribe((res:any)=>{
            this.projects = res;
          },
          err => {
            console.log(err)
          }
        );
      },
      err => {
        console.log(err)
      }
    );
  }

  selectReportType(project:any): void {
    let params ={
      projectId: project.id,
      projectName: project.full_name,
      imageUrl:project.imageUrl
    }
    this.router.navigate(['/main/reporttype'], { queryParams: params});
  }
}
