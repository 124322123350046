import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { UserService } from "src/app/services/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  public email:string;
  public password:string;
  private message:string;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
  }

  login(): void {
    if (!this.email || !this.password ) {
      this.emailInput.nativeElement.focus();
      return;
    }

    let data = {
      email : this.email,
      password : this.password
    }

    this.userService
      .login(data)
      .subscribe((res:any)=>{
        if(res.response === "SUCCESS") {
          this.userService.setToken(res.token);
          this.router.navigate(['/home']);
          swal.fire('Sesión iniciada correctamente','','success');
        }else {
          this.message = res.message;
          // swal.fire(res.message,'','error');
        }
  
        setTimeout(() => {
          if(swal.isVisible()) {
            swal.close();
          }
        },2000);
      },
      err => {
      }
    );
  }

  emailEnter(event) : void {
    if (event.charCode == 13) {
      this.passwordInput.nativeElement.focus();
    }
  }
  
  passwordEnter(event) : void {
    if (event.charCode == 13) {
      this.login();
    }
  }
}
