import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-simple-column',
  templateUrl: './simple-column.component.html',
  styleUrls: ['./simple-column.component.css']
})
export class SimpleColumnComponent implements OnInit {
  @Input() infotype;
	@Input() projectId;
	@Input() dateFrom;
	@Input() dateTo;
	@Input() type;
	@Input() type1;
	@Input() valoracion;
	@Input() topic;
	@Input() gender;
	@Input() edad;
	@Input() socieLevel;
	@Input() tipo;
	@Input() estado;
 
	@ViewChild('amchartSimpleColumn') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }
	ngOnChanges(){

		let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type: this.type,
      type1: this.type1,
			valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }

		this.chartService
      .getSimpleColumnData(data)
      .subscribe((res:any)=>{
				let chartdata = res;
				let maxCols = 6;
				function getHistogramData(source) {
					//console.log("EL SOURCE"+source)
					// Init
					let chartdata = [];
					let min = Math.min.apply(null, source);
					let max = Math.max.apply(null, source);
					let range = max - min;
					let step = range / maxCols;
					let totalAges = source.length;
					
					// Create items
					chartdata = [
						{
							from: 18,
							to: 24,
							count: 0,
							total:totalAges
						},
						{
							from: 25,
							to: 34,
							count: 0,
							total:totalAges
						},
						{
							from: 35,
							to: 44,
							count: 0,
							total:totalAges
						},
						{
							from: 45,
							to: 54,
							count: 0,
							total:totalAges
						},
						{
							from: 55,
							to: 64,
							count: 0,
							total:totalAges
						},
						{
							from: 65,
							to: max,
							count: 0,
							total:totalAges
						}
					];
					/*for(let i = 0; i < maxCols; i++) {
						let from = Math.round(min + i * step);
						let to = Math.round(min + (i + 1) * step);
						chartdata.push({
							from: from,
							to: to,
							count: 0,
							total:totalAges
						});
					}*/
					
					// Calculate range of the values
					console.log("SOURCE LENTG:",source.length )
					for(let i = 0; i < source.length; i++) {
						let value = source[i];
						console.log("VALUE:",value)
						
						let item = chartdata.find(function(el) {
							//console.log(el)
							return (value >= el.from) && (value <= el.to);
						});
						console.log("ID:",i,"ITEM",item)
						
						if(item != undefined){
							item.count++;
							item.total = ((item.count/totalAges)*100).toFixed(1);
						}else{continue;}
						
						
						
						
					}

					return chartdata;
				}

				// Create chart instance
				let chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
				chart.exporting.menu = new am4core.ExportMenu();

				// Add data
				chart.data = getHistogramData(chartdata);
				//console.log("EL CARDATA:"+ JSON.stringify(chart.data));

				// Create axes
				let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
				
				// categoryAxis.maxPrecision = 0;
				categoryAxis.dataFields.category = "from";
				categoryAxis.renderer.grid.template.location = 0;
				categoryAxis.renderer.minGridDistance = 10;

				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.calculateTotals = true;
				valueAxis.min = 0;
				valueAxis.max = 100;
				valueAxis.strictMinMax = true;
				valueAxis.renderer.minWidth = 50;
				valueAxis.renderer.labels.template.adapter.add("text", function(text) {
					return text + "%";
				  });
				valueAxis.maxPrecision = 0;
				// Create series
				let series = chart.series.push(new am4charts.ColumnSeries());
				series.dataFields.valueY = "total";
				series.dataFields.categoryX = "from";
				
				series.columns.template.tooltipText = "[bold]Edades:[/] {from} - {to}[/] \n[bold]Recuento:[/] {total}%";
      },
      err => {
      }
    );
	}
}
