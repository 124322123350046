import {Component, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';


@Component({
  selector: 'app-resumen-objetivos',
  templateUrl: './resumen-objetivos.component.html',
  styleUrls: ['./resumen-objetivos.component.css']
})
export class ResumenObjetivosComponent {
  heading = 'Pauta';
  @Input() cuentaId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;

  public cuentaName: string;
  private resumenObjetivosData: any = [];


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.pautaService
    .getCuentaNameById({cuentaId: this.cuentaId})
    .subscribe((res:any)=>{
      this.cuentaName = res[0].name;
    });

    this.getTableData();
  }

  getTableData() {
    console.log(this.cuentaId)
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      cuentaId : this.cuentaId
    };

    this.pautaService
      .getResumenObjetivosData(data)
      .subscribe((res:any)=>{
        let tempKey: number = 0;
        let tempSum: any = [];
        let tempSpan: any = [];
        let tempStr: string = "";
        res.forEach(element => {
          if (tempStr == element.plataforma) {
            tempSpan[(tempKey - 1)] += 1;
            tempSum[(tempKey - 1)] += element.resultados * 1;
          } else {
            tempStr = element.plataforma;
            tempKey += 1;
            tempSpan[(tempKey - 1)] = 1;
            tempSum[(tempKey - 1)] = element.resultados * 1;
          }
        });

        tempKey = 0;
        tempStr = "";
        res.forEach(element => {
          console.log("tempStr",tempStr, "element.plataforma",element.plataforma)
          if (tempStr == element.plataforma) {
            element.percent = element.resultados? Math.round(element.resultados/tempSum[(tempKey - 1)] * 10000) / 100 : 0;
            element.plataforma = "";
          } else {
            tempKey += 1;
            tempStr = element.plataforma;
            element.percent = element.resultados? Math.round(element.resultados/tempSum[(tempKey - 1)] * 10000) / 100 : 0;
            element.rowSpan = tempSpan[(tempKey - 1)];
            this.resumenObjetivosData.push({plataforma: element.plataforma, resultados: /*tempSum[(tempKey - 1)]*/'', rowSpan: 1});
            //console.log("resumenObjetivosData",this.resumenObjetivosData);
            element.plataforma = "";
          }
          this.resumenObjetivosData.push(element);
        });
      },
      err => {
        console.log(err)
      }
    );
    
  }
}
