import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-moving-bullets',
  templateUrl: './moving-bullets.component.html',
  styleUrls: ['./moving-bullets.component.css']
})
export class MovingBulletsComponent implements OnInit {
  @Input() infotype;
	@Input() projectId;
	@Input() dateFrom;
	@Input() dateTo;
	@Input() valoracion;
	@Input() topic;
	@Input() gender;
	@Input() edad;
	@Input() socieLevel;
	@Input() tipo;
	@Input() estado;
  @ViewChild('amchartMovingBullets') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
	}
	ngOnChanges() {
		let chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
		chart.exporting.menu = new am4core.ExportMenu();
	
		chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chart.paddingBottom = 30;

		let data = {
      infotype : this.infotype,
			id : this.projectId,
			dateFrom : this.dateFrom,
			dateTo : this.dateTo,
			valoracion: this.valoracion,
			topic: this.topic,
			gender: this.gender,
			edad: this.edad,
			socieLevel: this.socieLevel,
			tipo: this.tipo,
			estado: this.estado
		}

		this.chartService
			.getMovingBulletsData(data)
			.subscribe((res:any)=>{
				chart.data = res;
				console.log(res)
				let tematicas = [
					{
						"term":"salud",
						"path":"./assets/images/main/icons/salud.svg"
					},
					{
						"term":"atributo",
						"path":"./assets/images/main/icons/atributos.svg"
					},
					{
						"term":"econom",
						"path":"./assets/images/main/icons/economia.svg"
					},
					{
						"term":"corrupc",
						"path":"./assets/images/main/icons/corrupcion.svg"
					},
					{
						"term":"movilidad",
						"path":"./assets/images/main/icons/movilidad.svg"
					},
					{
						"term":"medio",
						"path":"./assets/images/main/icons/medham.svg"
					},
					{
						"term":"Desa",
						"path":"./assets/images/main/icons/desaSoc.svg"
					},
					{
						"term":"deporte",
						"path":"./assets/images/main/icons/deporte.svg"
					},
					{
						"term":"gest",
						"path":"./assets/images/main/icons/gestion.svg"
					},
					{
						"term":"seguridad",
						"path":"./assets/images/main/icons/seguridad.svg"
					},
					{
						"term":"educa",
						"path":"./assets/images/main/icons/educacion.svg"
					},
					{
						"term":"servicio",
						"path":"./assets/images/main/icons/PublicServ.svg"
					},
					{
						"term":"contra ",
						"path":"./assets/images/main/icons/contrasis.svg"
					},
					{
						"term":"electoral",
						"path":"./assets/images/main/icons/electoral.svg"
					},
					{
						"term":"tradiciones",
						"path":"./assets/images/main/icons/tradiciones.svg"
					},
					{
						"term":"ciuda",
						"path":"./assets/images/main/icons/AtenCiud.svg"
					},
					{
						"term":"trabajo",
						"path":"./assets/images/main/icons/trabajo.svg"
					},
					{
						"term":"comunica",
						"path":"./assets/images/main/icons/comunicacion.svg"
					},
					{
						"term":"sectores prod",
						"path":"./assets/images/main/icons/sectprod.svg"
					},
					{
						"term":"identidad part",
						"path":"./assets/images/main/icons/partidopol.svg"
					},
					{
						"term":"guerra",
						"path":"./assets/images/main/icons/guerra.svg"
					},
					{
						"term":"derecho",
						"path":"./assets/images/main/icons/derechos.svg"
					},
					{
						"term":"protec",
						"path":"./assets/images/main/icons/proteccion.svg"
					},
					{
						"term":"plan",
						"path":"./assets/images/main/icons/planeacion.svg"
					},
					
					{
						"term":"género",
						"path":"./assets/images/main/icons/genero.svg"
					},
					{
						"term":"proceso",
						"path":"./assets/images/main/icons/democratico.svg"
					},
					{
						"term":"transparencia",
						"path":"./assets/images/main/icons/transparencia.svg"
					},
					{
						"term":"cultura",
						"path":"./assets/images/main/icons/cultura.svg"
					},
					{
						"term":"just",
						"path":"./assets/images/main/icons/justicia.svg"
					},
					
				]
				
				tematicas.forEach(element1 => {
					let element = element1.term;
					try {
						var rgxp = new RegExp(element, "gi");
						let findInTem = chart.data.findIndex(obj => obj.sector.match(rgxp));
						chart.data[findInTem]['href'] = element1.path;
					} catch {
					}
				});
				

				let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
				categoryAxis.dataFields.category = "sector";
				categoryAxis.renderer.grid.template.strokeOpacity = 0;
				categoryAxis.renderer.minGridDistance = 10;
				categoryAxis.renderer.labels.template.dy = 35;
				//categoryAxis.renderer.labels.template.dx = -10;
				categoryAxis.renderer.tooltip.dy = 35;
				categoryAxis.renderer.labels.template.rotation = -45;

				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				//valueAxis.numberFormatter = new am4core.NumberFormatter();
				//valueAxis.numberFormatter.numberFormat = "{valueY.percent.formatNumber('#.0')}%"; 
				valueAxis.calculateTotals = true;
				valueAxis.min = 0;
				//valueAxis.max = 100;
				//valueAxis.strictMinMax = true;
				//valueAxis.renderer.minWidth = 50;
				valueAxis.renderer.labels.template.adapter.add("text", function(text) {
					return text + "%";
				  });
				valueAxis.renderer.inside = true;
				valueAxis.renderer.labels.template.fillOpacity = 0.3;
				valueAxis.renderer.grid.template.strokeOpacity = 0;
				
				valueAxis.cursorTooltipEnabled = false;
				valueAxis.renderer.baseGrid.strokeOpacity = 0;

				let series = chart.series.push(new am4charts.ColumnSeries);
				series.calculatePercent = true;
				series.dataFields.valueYShow = "percent";
				series.dataFields.valueY = "size";
				series.dataFields.categoryX = "sector";
				series.tooltipText = "{sector}: {valueY.percent.formatNumber('#.0')}%";
				series.tooltip.pointerOrientation = "horizontal";
				series.tooltip.dy = - 6;
				series.columnsContainer.zIndex = 100;

				let columnTemplate = series.columns.template;
				columnTemplate.width = am4core.percent(50);
				columnTemplate.maxWidth = 65;
				columnTemplate.column.cornerRadius(60, 60, 10, 10);
				columnTemplate.strokeOpacity = 0;

				series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#DCDCDC"), max: am4core.color("#DCDCDC") });
				series.mainContainer.mask = undefined;

				let cursor = new am4charts.XYCursor();
				chart.cursor = cursor;
				cursor.lineX.disabled = true;
				cursor.lineY.disabled = true;
				cursor.behavior = "none";

				let bullet = columnTemplate.createChild(am4charts.CircleBullet);
				bullet.circle.radius = 30;
				bullet.valign = "bottom";
				bullet.align = "center";
				bullet.isMeasured = true;
				
				bullet.verticalCenter = "bottom";
				bullet.interactionsEnabled = false;

				let hoverState = bullet.states.create("hover");
				let outlineCircle = bullet.createChild(am4core.Circle);
				outlineCircle.adapter.add("radius", function (radius, target) {
						let circleBullet = target.parent;
						return circleBullet["circle"].pixelRadius + 10;
				})

				let image = bullet.createChild(am4core.Image);
				image.width = 50;
				image.height = 50;
				image.horizontalCenter = "middle";
				image.verticalCenter = "middle";
				image.propertyFields.href = "href";

				image.adapter.add("mask", function (mask, target) {
					let circleBullet = target.parent;
					return circleBullet["circle"];
				})

				let watermark = new am4core.Image();
				watermark.href = "./assets/images/C.png";
				chart.chartContainer.children.push(watermark);
				watermark.align = "center";
				watermark.valign = "bottom";
				watermark.opacity = 0.2;
				watermark.width = 100;
				watermark.height = 50;

				let previousBullet;
				chart.cursor.events.on("cursorpositionchanged", function (event) {
					let dataItem = series.tooltipDataItem;

					if (dataItem['column']) {
						let bullet = dataItem["column"].children.getIndex(1);

						if (previousBullet && previousBullet != bullet) {
							previousBullet.isHover = false;
						}

						if (previousBullet != bullet) {

							let hs = bullet.states.getKey("hover");
							hs.properties.dy = -bullet.parent.pixelHeight + 30;
							bullet.isHover = true;

							previousBullet = bullet;
						}
					}
				});
			},
			err => {
			}
		);
	}
}
