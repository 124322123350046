import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

import { ErrorHandlerService } from "./error-handler.service";  

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  //private url = "http://localhost:3000";
  private url = "http://18.223.121.79:3000";

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
    private router: Router
  ) {}

  getProjects(data) {
    return this.http
      .post(this.url+"/project/getProjects", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getProjects")));
  }

  getAssignedProject(data) {
    return this.http
      .post(this.url+"/assignedProject/getAssignedProject", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getAssignedProject")));
  }

  saveProjectIds(data) {
    return this.http
      .post(this.url+"/assignedProject/saveProjectIds", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("saveProjectIds")));
  }
}
