import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";


am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-gauge-with-bands',
  templateUrl: './gauge-with-bands.component.html',
  styleUrls: ['./gauge-with-bands.component.css']
})
export class GaugeWithBandsComponent implements OnInit {
  @Input() infotype;
	@Input() projectId;
	@Input() dateFrom;
	@Input() dateTo;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartGaugeWithBands') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }

  ngOnChanges() {
    let data = {
      infotype : this.infotype,
			id : this.projectId,
			dateFrom : this.dateFrom,
			dateTo : this.dateTo,
      valoracion: this.valoracion,
			topic: this.topic,
			gender: this.gender,
			edad: this.edad,
			socieLevel: this.socieLevel,
			tipo: this.tipo,
			estado: this.estado
		}
    this.chartService
			.getGaugeData(data)
			.subscribe((res:any)=>{
				let chartMin = -100;
        let chartMax = 100;
        let data = {
          score: res[0],
          gradingData: [
            {
              title: " 😩 ",
              color: "#ee1f25",
              lowScore: -100,
              highScore: -60
            },
            {
              title: " 😞 ",
              color: "#fdae19",
              lowScore: -60,
              highScore: -20
            },
            {
              title: " 😐 ",
              color: "#f3eb0c",
              lowScore: -20,
              highScore: 20
            },
            {
              title: " 🙂 ",
              color: "#54b947",
              lowScore: 20,
              highScore: 60
            },
            {
              title: " 🤩 ",
              color: "#0f9747",
              lowScore: 60,
              highScore: 100
            }
          ]
        };
        /**
        Grading Lookup
        */
        function lookUpGrade(lookupScore, grades) {
          // Only change code below this line
          for (var i = 0; i < grades.length; i++) {
            if (
              grades[i].lowScore < lookupScore &&
              grades[i].highScore >= lookupScore
            ) {
              return grades[i];
            }
          }
          return null;
        }

        // create chart
        let chart = am4core.create(this.chartElement.nativeElement, am4charts.GaugeChart);
        chart.exporting.menu = new am4core.ExportMenu();
        chart.hiddenState.properties.opacity = 0;
        chart.fontSize = 15;
        chart.innerRadius = am4core.percent(80);
        chart.resizable = true;

        /**
         * Normal axis
         */

        let axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis.min = chartMin;
        axis.max = chartMax;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = true;
        axis.renderer.line.strokeOpacity = 0.1;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.strokeWidth = 0.5;
        axis.renderer.ticks.template.length = 5;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(15);
        axis.renderer.labels.template.fontSize = "0.9em";

        /**
         * Axis for ranges
         */

        let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis2.min = chartMin;
        axis2.max = chartMax;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = false;
        axis2.renderer.grid.template.opacity = 0.5;
        axis2.renderer.labels.template.bent = true;
        axis2.renderer.labels.template.fill = am4core.color("#000");
        axis2.renderer.labels.template.fontWeight = "bold";
        axis2.renderer.labels.template.fillOpacity = 0.3;
        /**
        Ranges
        */

        for (let grading of data.gradingData) {
          let range = axis2.axisRanges.create();
          range.axisFill.fill = am4core.color(grading.color);
          range.axisFill.fillOpacity = 0.8;
          range.axisFill.zIndex = -1;
          range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
          range.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
          range.grid.strokeOpacity = 0;
          // range.stroke = am4core.color(grading.color).lighten(-0.1);
          range.label.inside = true;
          range.label.text = grading.title.toUpperCase();
          range.label.inside = true;
          range.label.location = 0.5;
          range.label.inside = true;
          // range.label.radius = am4core.percent(10);
          range.label.paddingBottom = -5; // ~half font size
          range.label.fontSize = "0.9em";
        }

        let matchingGrade = lookUpGrade(data.score, data.gradingData);

        /**
         * Label 1
         */

        let label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = "6em";
        label.x = am4core.percent(50);
        label.paddingBottom = 15;
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        //label.dataItem = data;
        //label.text = data.score.toFixed(1);
        label.text = data.score.toFixed(0) + "%";
        label.fill = am4core.color(matchingGrade.color);

        /**
         * Label 2
         */

        let label2 = chart.radarContainer.createChild(am4core.Label);
        label2.isMeasured = false;
        label2.fontSize = "2em";
        label2.horizontalCenter = "middle";
        label2.verticalCenter = "bottom";
        label2.text = matchingGrade.title.toUpperCase();
        label2.fill = am4core.color(matchingGrade.color);
        /**
         * Hand
         */
        let hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(55);
        hand.startWidth = 8;
        hand.pin.disabled = true;
        hand.value = data.score;
        hand.fill = am4core.color("#444");
        hand.stroke = am4core.color("#000");
			},
			err => {
			}
		);
  }
}
