import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

import { ErrorHandlerService } from "./error-handler.service";  

@Injectable({
  providedIn: "root",
})
export class PautaService {
  //private url = "http://localhost:3000";
  private url = "http://18.223.121.79:3000";

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
    private router: Router
  ) {}

  getResumenGastosModeloData(data) {
    return this.http
      .post(this.url+"/pauta/getResumenGastosModeloData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getResumenGastosModeloData")));
  }

  getEstrategiaData(data) {
    return this.http
      .post(this.url+"/pauta/getEstrategiaData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getEstrategiaData")));
  }

  getEstrategiaTotalData(data) {
    return this.http
      .post(this.url+"/pauta/getEstrategiaTotalData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getEstrategiaTotalData")));
  }

  getPlataformaTotalData(data) {
    return this.http
      .post(this.url+"/pauta/getPlataformaTotalData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getPlataformaTotalData")));
  }

  getDesglosePlataformaData(data) {
    return this.http
      .post(this.url+"/pauta/getDesglosePlataformaData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getDesglosePlataformaData")));
  }

  getDesgloseTotalData(data) {
    return this.http
      .post(this.url+"/pauta/getDesgloseTotalData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getDesgloseTotalData")));
  }

  getGastoPlataformaData(data) {
    return this.http
      .post(this.url+"/pauta/getGastoPlataformaData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getGastoPlataformaData")));
  }

  getGastoPlataformaChartData(data) {
    return this.http
      .post(this.url+"/pauta/getGastoPlataformaChartData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getGastoPlataformaChartData")));
  }

  getGastoEstrategiaData(data) {
    return this.http
      .post(this.url+"/pauta/getGastoEstrategiaData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getGastoEstrategiaData")));
  }

  getGastoPautasData(data) {
    return this.http
      .post(this.url+"/pauta/getGastoPautasData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getGastoPautasData")));
  }

  getResumenObjetivosData(data) {
    return this.http
      .post(this.url+"/pauta/getResumenObjetivosData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getResumenObjetivosData")));
  }

  getHistoricoCprData(data) {
    return this.http
      .post(this.url+"/pauta/getHistoricoCprData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getHistoricoCprData")));
  }

  getControlCampanasData(data) {
    return this.http
      .post(this.url+"/pauta/getControlCampanasData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getControlCampanasData")));
  }

  getDesempenoReporduccionesData(data) {
    return this.http
      .post(this.url+"/pauta/getDesempenoReporduccionesData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getDesempenoReporduccionesData")));
  }

  getDesempenoInteraccionesData(data) {
    return this.http
      .post(this.url+"/pauta/getDesempenoInteraccionesData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getDesempenoInteraccionesData")));
  }

  getCuentaNameById(data) {
    return this.http
      .post(this.url+"/pauta/getCuentaNameById", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getCuentaNameById")));
  }

}
