import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";

am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.css']
})
export class ProgressChartComponent implements OnInit {
  @Input() infotype;
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartProgressChart') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }

  ngOnChanges () {
    let chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type: 'progress-chart',
      valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }

    this.chartService
      .getProgressChartData(data)
      .subscribe((res:any)=>{
        chart.data = [];
        res[0].category = "";
        res[0].from = 0;
        res[0].too = (res[0].to ? res[0].to : 0);
        res[0].to = (res[0].to? res[0].to : 0);
        res[0].fill = am4core.color("#2E489C");
        

        res[1].category = "";
        res[1].too = (res[1].to ? res[1].to *1 : 0);
        res[1].from = res[0].to;
        res[1].to = res[0].to * 1 + (res[1].to? res[1].to *1 : 0);
        res[1].fill = am4core.color("#950000");
        
        

        res[2].category = "";
        res[2].from = res[1].to;
        res[2].too = (res[2].to ? res[2].to *1 : 0);
        res[2].to = res[1].to * 1 + (res[2].to? res[2].to *1 : 0);
        res[2].fill = am4core.color("#313131");
        

        let data = [];
        for (let index = 0; index < res.length; index++) {
          if(res[index].from != res[index].to) {
            data.push(res[index]);
          }
        }
        chart.data = data;
        //console.log(data);

        let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.disabled = true;
        
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.labels.template.disabled = true;
        xAxis.min = 0;
        xAxis.max = 100;
        
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "to";
        series.dataFields.openValueX = "from";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "fill";
        series.columns.template.strokeOpacity = 0;
        series.columns.template.height = am4core.percent(100);
        series.name = "nombre";
        
        // Ranges/labels
        chart.events.on("beforedatavalidated", function(ev) {
          let data = chart.data;
          for(var i = 0; i < data.length; i++) {
            let range = xAxis.axisRanges.create();
            range.value = data[i].to;
            range.label.text = data[i].too.toFixed(1)+"%";
            range.label.horizontalCenter = "right";
            range.label.paddingLeft = 10;
            range.label.paddingTop = 5;
            range.label.fontSize = 12;
            range.grid.strokeOpacity = 0.2;
            range.tick.length = 18;
            range.tick.strokeOpacity = 0.2;
          }
        });
        
        // Legend
        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.clickable = false;
        legend.itemContainers.template.focusable = false;
        legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        legend.align = "center";
        legend.data = chart.data;
      },
      err => {
      }
    );
  }
}
