/*Valoración de las temáticas*/
import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";

// Themes begin
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-bar-dataviz',
  templateUrl: './bar-dataviz.component.html',
  styleUrls: ['./bar-dataviz.component.css']
})
export class BarDataVizComponent implements OnInit {
  @Input() infotype;
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartBarDataviz') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }

  ngOnChanges() {
    let chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);

    chart.data = [];

    let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type: 'bar_dataviz',
      valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }
    
    this.chartService
      .getBarDatabizData(data)
      .subscribe((res:any)=>{
        //console.log(res)
        let pepe:any = this.noMasCero(res);
        
        chart.data = pepe;

        //console.log(chart.data)

        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.legend.position = "right";

        // Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "topic";
        categoryAxis.renderer.grid.template.opacity = 0;
        categoryAxis.renderer.minGridDistance = 10;
  


        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.opacity = 0;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.ticks.template.length = 10;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 10;
        valueAxis.max = 100;
        
        

        chart.colors.list = [
          am4core.color("#2E489C"),
          am4core.color("#950000"),
          am4core.color("#313131"),
        ];

        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.numberFormatter.numberFormat = "#.0";

        // Create series
        function createSeries(field, name) {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueX = field;
          series.dataFields.categoryY = "topic";
          series.stacked = true;
          series.name = name;

                       
          
          let labelBullet = series.bullets.push(new am4charts.LabelBullet());
          labelBullet.locationX = 0.5;
          labelBullet.label.text = "{valueX}%";
          labelBullet.label.fill = am4core.color("#fff");
        }

        createSeries("positive", "Positivo");
        createSeries("negative", "Negativo");
        createSeries("neutral", "Neutral");

        let watermark = new am4core.Image();
        
        watermark.href = "./assets/images/wm-capisci.png";
        chart.children.push(watermark);
        watermark.align = "right";
        watermark.valign = "bottom";
        watermark.opacity = 0.2;
        watermark.marginRight = 10;
        watermark.marginBottom = -10;
        watermark.width = 100;
        watermark.height = 50;
      },
      err => {
      }
    );
  }

  noMasCero(data){
    //console.log("nomascero",data)
    /*[{negative: 35,neutral:15,positive: 50,topic: "Seguridad "}]*/
    data.forEach(element => {
      if(element.positive <= 0){
        
        element.neutral = element.neutral - 2.5;
        element.negative = element.negative - 2.5;
        element.positive = 100 - (element.neutral + element.negative);

      }
      if(element.negative <= 0){
        element.positive = element.positive - 2.5;
        element.neutral = element.neutral - 2.5;
        element.negative = 100 - (element.positive + element.neutral);
        
      }
      if(element.neutral <= 0){
        element.positive = element.positive - 2.5;
        element.negative = element.negative - 2.5;
        element.neutral = 100 - (element.positive + element.negative);
      }

      
      
    });
    return data;


  }
  
}
