import {Component, OnInit,ElementRef, ViewChild, Input} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import Swal from 'sweetalert2';

import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { ChartService } from "src/app/services/chart.service";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { jsonpCallbackContext } from '@angular/common/http/src/module';


// import am4plugins_wordCloud from 'wordcloud';

am4core.useTheme(am4themes_spiritedaway);
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
  selector: 'app-spiritedaway',
  templateUrl: './spiritedaway.component.html',
  styleUrls: ['./spiritedaway.component.css']
})
export class SpiritedAwayComponent implements OnInit {
  @Input() infotype;
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() type1;
  @Input() valoracion;
  @Input() topic;
  @Input() gender;
  @Input() edad;
  @Input() socieLevel;
  @Input() tipo;
  @Input() estado;
  @ViewChild('amchartSpiritedAway') chartElement: ElementRef<HTMLElement>;

  constructor(private chartService: ChartService) {
  }

  ngOnInit(){
  }
  ngOnChanges() {
    let data = {
      infotype : this.infotype,
      id : this.projectId,
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
			type1: this.type1,
      valoracion: this.valoracion,
      topic: this.topic,
      gender: this.gender,
      edad: this.edad,
      socieLevel: this.socieLevel,
      tipo: this.tipo,
      estado: this.estado
    }

    // Create chart instance
    let chart = am4core.create(this.chartElement.nativeElement, am4plugins_wordCloud.WordCloud);
    chart.exporting.menu = new am4core.ExportMenu();
    let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
    
    series.accuracy = 4;
    series.step = 15;
    series.rotationThreshold = 0.7;
    series.maxCount = 200;
    series.minWordLength = 2;
    series.labels.template.margin(4,4,4,4);
    series.maxFontSize = am4core.percent(30);
   
    this.chartService
      .getSpiritedawayData(data)
      .subscribe((res:any)=>{
        //console.log("NUBE"+ JSON.stringify(res))
				series.data = res;
        series.dataFields.word = "title";
        series.dataFields.value = "number";
    
        series.colors = new am4core.ColorSet();
        series.colors.passOptions = {}; // makes it loop
        if (this.type1 == 'positive') {
          series.colors.list = [
            am4core.color("#2E489C"),
          ];  
        } else if (this.type1 == 'negative') {
          series.colors.list = [
            am4core.color("#950000"),
          ];  
        } else {
          series.colors.list = [
            am4core.color("#313131"),
          ];
        }

        series.labels.template.events.on("hit", function(ev) {
          //console.log("clicked on ",this);
          var indexNum = series.data.findIndex(obj => obj.title.match(ev.target.currentText));
          var losPosts =[]
          var elHTML = ''
          //console.log("ANTES DETALLE",series.data[indexNum])
          //console.log(series.data[indexNum].detalles)
          for (let indexPost = 0; indexPost < series.data[indexNum].detalles.length; indexPost++) {
            let element = series.data[indexNum].detalles[indexPost].publicacion;
            let elementID = series.data[indexNum].detalles[indexPost].idPub;
            //console.log(element)
            if(losPosts.length == 0){

              losPosts.push({
                title:element,
                idPost:series.data[indexNum].detalles[indexPost].idPub,
                comments:[[series.data[indexNum].detalles[indexPost].comentario,series.data[indexNum].detalles[indexPost].genero]],
                usuario:series.data[indexNum].detalles[indexPost].usuario,
                genero:series.data[indexNum].detalles[indexPost].genero
              })
            }else{
              var indPost = losPosts.findIndex(obj => obj.idPost.match(elementID));
              if(indPost == -1){
                losPosts.push({
                  title:element,
                  idPost:series.data[indexNum].detalles[indexPost].idPub,
                  comments:[[series.data[indexNum].detalles[indexPost].comentario,series.data[indexNum].detalles[indexPost].genero]],
                  usuario:series.data[indexNum].detalles[indexPost].usuario,
                  genero:series.data[indexNum].detalles[indexPost].genero
                })
              }else{
                losPosts[indPost].comments.push([series.data[indexNum].detalles[indexPost].comentario,series.data[indexNum].detalles[indexPost].genero])
              }

            }
            
            
          }
          
          console.log(losPosts)
          

          //Swal.fire(this.type1,"You clicked: "+ev.target.currentText+" \n\n"+ JSON.stringify(series.data[indexNum].detalles), "success");
          for (let indexText = 0; indexText < losPosts.length; indexText++) {
            let titulo = losPosts[indexText].title;
            let comentario = losPosts[indexText].comments
            let idpublicacion = losPosts[indexText].idPost
            
            //let usuario = losPosts[indexText].usuario
            /*
            <div class="card-hover-shadow-2x mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i class="header-icon lnr-database icon-gradient bg-malibu-beach"></i>
                    Tasks List 
                </div>
              </div>
              <div class="scroll-area-lg">
                <perfect-scrollbar ng-reflect-auto-propagation="true" class="ps-show-limits">
                  <div style="position: static;" class="ps" ng-reflect-disabled="false">
                    <div class="ps-content">
                      <ul class="todo-list-wrapper list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="todo-indicator bg-focus"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                <div class="widget-heading">Task with dropdown menu</div>
                                <div class="widget-subheading">
                                  <div>By Johnny </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div><!--bindings={"ng-reflect-ng-if": "false"}-->
                    <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                    </div>
                    <div class="ps__rail-y" style="top: 0px; height: 400px; right: 0px;">
                      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
             */
            elHTML += `<div class="col-sm-12 col-lg-12"><div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
              <div class="card-header-title font-size-lg text-capitalize widget-heading">
                <i class="header-icon icon-gradient bg-malibu-beach"></i>
               ${titulo}
              </div>
            </div>
            <div>
                <perfect-scrollbar ng-reflect-auto-propagation="true" class="ps-show-limits">
                  <div style="position: static;" class="ps" ng-reflect-disabled="false">
                    <div class="ps-content">`
            for (let indexComent = 0; indexComent < comentario.length; indexComent++) {
              let comet = comentario[indexComent];
              elHTML += `<ul class="todo-list-wrapper list-group list-group-flush">
              <li class="list-group-item">
                <div class="todo-indicator bg-indicator"></div>
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-2"></div>
                    <div class="widget-content-left mr-3"><div class="widget-content-left"><img alt="" class="rounded" src="${comet[1] == "Femenino"?"./assets/images/avatars/mujer.png":comet[1]=="Masculino"?"./assets/images/avatars/hombre.png":"./assets/images/avatars/indefonido.png"}" width="42"></div></div>
                    <div class="widget-content-left"><div class="widget-subheading">${comet[0]}</div></div> 
                  
                  </div>
                </div>
              </li>
            </ul>`
              
            }
            elHTML +=`</div><!--bindings={"ng-reflect-ng-if": "false"}-->
                    <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                    </div>
                    <div class="ps__rail-y" style="top: 0px; height: 400px; right: 0px;">
                      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
              <div class="d-block text-right card-footer">
            
                <button  class="btn btn-primary" onClick="window.open('https://facebook.com/${idpublicacion}','_blank');" >VER PUBLICACIÓN</button>
              </div>
            </div></div>`
            
            
          }
          Swal.fire({
            title: '<strong>'+ev.target.currentText+'</strong>',
            icon: 'info',
            html:elHTML,
            showCloseButton: true,
            showCancelButton: false
          })
        }, this);
        
        series.angles = [0,0];
        series.fontWeight = "500"
      },
      err => {
      }
    );
  }
}
