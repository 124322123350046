import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { UserService } from '../../../../../services/user.service';
import { PautaService } from '../../../../../services/pauta.service';
import { forEach } from '@angular/router/src/utils/collection';

am4core.useTheme(am4themes_animated);

function shuffleArray(arr) {
  arr.sort(() => Math.random() - 0.5);
}

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
        am4core.color("#94D40B"),
        am4core.color("#FA4516"),
        am4core.color("#42C7E6"),
        am4core.color("#0056B9"),
        am4core.color("#F4B220"),
        am4core.color("#85329A"),
        am4core.color("#490E68")
    ];
    shuffleArray(target.list);
  }
}



am4core.useTheme(am4themes_myTheme);

@Component({
  selector: 'app-desempeno-reporducciones',
  templateUrl: './desempeno-reporducciones.component.html',
  styleUrls: ['./desempeno-reporducciones.component.css']
})
export class DesempenoReporduccionesComponent {
  heading = 'Pauta';
  @Input() projectId;
  @Input() dateFrom;
  @Input() dateTo;
  @Input() filterType;
  @ViewChild('amchartReporducciones') chartReporducciones: ElementRef<HTMLElement>;
  @ViewChild('amchartInteracciones') chartInteracciones: ElementRef<HTMLElement>;

  private desempenoReporduccionesData: any = {};


  constructor(private userService: UserService,  private pautaService: PautaService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.getTableData();
  }

  getTableData() {
    let data = {
      dateFrom : this.dateFrom,
      dateTo : this.dateTo,
      projectId : this.projectId
    };


    this.pautaService
      .getDesempenoReporduccionesData(data)
      .subscribe((res:any)=>{
        console.log(res)
        this.drawChart(res);
    },
      err => {
        console.log(err)
      }
    );

    this.pautaService
      .getDesempenoInteraccionesData(data)
      .subscribe((res:any)=>{
        console.log(res)
        this.drawChart2(res);
    },
      err => {
        console.log(err)
      }
    );
  }

  /*drawChart(data) {

    let chart = am4core.create(this.chartReporducciones.nativeElement, am4charts.XYChart);

    let valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.renderer.ticks.template.disabled = true;
    valueAxisX.renderer.axisFills.template.disabled = true;

    let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.renderer.ticks.template.disabled = true;
    valueAxisY.renderer.axisFills.template.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "value";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip.pointerOrientation = "vertical";

    let bullet = series.bullets.push(new am4core.Circle());
    bullet.fill = am4core.color("#ff0000");
    bullet.propertyFields.fill = "color";
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0.5;
    bullet.stroke = am4core.color("#ffffff");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "[bold]{title}:[/]\nTotal: {value.value}\nCPR: {valueX.value}\nProportion:{valueY.value}";

    let outline = chart.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 0.8;
    outline.stroke = am4core.color("#ff0000");
    outline.strokeWidth = 2;
    outline.hide(0);

    let blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);

    bullet.events.on("over", function(event) {
        let target = event.target;
        outline.radius = target.pixelRadius + 2;
        outline.x = target.pixelX;
        outline.y = target.pixelY;
        outline.show();
    })

    bullet.events.on("out", function(event) {
        outline.hide();
    })

    let hoverState = bullet.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.strokeOpacity = 1;

    series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });

    bullet.adapter.add("tooltipY", function (tooltipY, target) {
        return -target.radius;
    })

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.cursor.snapToSeries = series;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    chart.data = data;
  }*/

  drawChart(data){
    console.log(data)
    let yearData = {};
    let firstYear = 2021;
    let lastYear = 2022;
    yearData[firstYear] = data;

    /*for (var year = firstYear; year < lastYear; year++) {
      let data = []
      yearData[firstYear] = data;

      for (var i = 0; i < 50; i++) {
        if (year == firstYear) {
          data.push({ x: Math.round(Math.random() * 180 - 90), y: Math.round(Math.random() * 140 - 70), radius: Math.round(Math.random() * 1000) });
        }
        else {
          let previous = yearData[year - 1][i];
          data.push({ 
            x: previous.x + Math.round(Math.random() * 8 - 4), 
            y: previous.y + Math.round(Math.random() * 8 - 4), 
            radius: Math.abs(previous.radius + Math.round(Math.random() * 200 - 100)) });
        }
      }
    }*/

    function findMinMax(key) {
      const datas = data.map((node) => node[key]);
      return {
        min: Math.min(datas),
        max: Math.max(datas),
      }
    }

    /*console.log(findMinMax('range'));
      console.log(findMinMax('time'));*/

    // Create chart instance
    let chart = am4core.create(this.chartReporducciones.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    let maxMinX = findMinMax('x')
    let maxMinY = findMinMax('y')

    // Create axes
    let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.title.text = 'CPR';
    xAxis.min = maxMinX.min;
    xAxis.max = maxMinX.max;
    xAxis.keepSelection = true;
    xAxis.renderer.grid.template.above = true;
    xAxis.renderer.labels.template.adapter.add("text", function(text) {
      return "$" +text;
    });

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.title.text = 'Proporción de resultados';
    yAxis.min = maxMinY.min;
    yAxis.max = maxMinY.max;
    yAxis.keepSelection = true;
    yAxis.renderer.grid.template.above = true;

    // Create color series
    
    //PROMY
    let promy = 0.0
    data.forEach(e => {promy = promy + parseFloat(e.y) });
    promy = promy / data.length;
    console.log("promy", promy)

    //PROMX
    let promx = 0.0
    data.forEach(e => {promx = promx + parseFloat(e.x) });
    console.log("promx", promx)
    promx = promx / data.length;
    console.log("promx", promx)

    // top right
    let series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.valueX = "ax";
    series4.dataFields.valueY = "ay";
    series4.strokeOpacity = 0;
    series4.fill = am4core.color("gray");
    series4.ignoreMinMax = true;
    series4.fillOpacity = 1;
    series4.data = [{
      "ax": promx * 0.99,
      "ay": 1000
    }, {
      "ax": promx,
      "ay": 1000
    }]

    // bottom right
    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueX = "ax";
    series3.dataFields.valueY = "ay";
    series3.strokeOpacity = 0;
    series3.fill = am4core.color("gray");
    series3.ignoreMinMax = true;
    series3.fillOpacity = 1;
    series3.data = [{
      "ax": 0,
      "ay": promy
    }, {
      "ax": 1000,
      "ay": (promy)
    }
    , {
      "ax": 1000,
      "ay": (0.99 * promy)
    }
    , {
      "ax": 0,
      "ay": (0.99 * promy)
    }
  ]



    // bubble series

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "y";
    series.strokeOpacity = 0;


    let bullet = series.bullets.push(new am4core.Circle());
    //bullet.fill = am4core.color("red");
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0.8;
    bullet.stroke = am4core.color("#ffffff");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "Nombre: {title}\n Gasto: ${value.value}\n CPR: ${valueX.value} \nResultados: {valueY.value}";


    bullet.events.on("over", function(event) {
      let target = event.target;
      chart.cursor.triggerMove({ x: target.pixelX, y: target.pixelY }, "hard");
      chart.cursor.lineX.y = target.pixelY;
      chart.cursor.lineY.x = target.pixelX - chart.plotContainer.pixelWidth;
      xAxis.tooltip.disabled = false;
      yAxis.tooltip.disabled = false;
    })

    bullet.events.on("out", function(event) {
      chart.cursor.triggerMove(event.pointer.point, "none");
      chart.cursor.lineX.y = 0;
      chart.cursor.lineY.x = 0;
      xAxis.tooltip.disabled = true;
      yAxis.tooltip.disabled = true;
    })

    series.heatRules.push({ target: bullet, min: 2, max: 30, property: "radius" });
    series.data = yearData[firstYear];



    // Scrollbars
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();




    let label = chart.plotContainer.createChild(am4core.Label)
    label.fontSize = 60;
    label.fillOpacity = 0.4;
    label.align = "center";
    label.zIndex = 1000;


    let sliderContainer = chart.bottomAxesContainer.createChild(am4core.Container);
    sliderContainer.width = am4core.percent(100);
    sliderContainer.layout = "horizontal";


    let playButton = sliderContainer.createChild(am4core.PlayButton);
    playButton.valign = "middle";
    playButton.events.on("toggled", function(event) {
      if (event.target.isActive) {
        playSlider();
      }
      else {
        stopSlider();
      }
    })
    playButton.disabled = true;

    let slider = sliderContainer.createChild(am4core.Slider);
    slider.valign = "middle";
    slider.margin(0, 0, 0, 0);
    slider.marginLeft = 30;
    slider.height = 15;

    slider.startGrip.events.on("drag", stop);
    slider.disabled = true;


    let sliderAnimation = slider.animate({ property: "start", to: 1 }, 80000, am4core.ease.linear).pause();
    sliderAnimation.events.on("animationended", function() {
      playButton.isActive = false;
    })

    slider.events.on("rangechanged", function() {
      let year = firstYear + Math.round(slider.start * (lastYear - firstYear - 1));
      let data = yearData[year];
      for (var i = 0; i < series.data.length; i++) {
        let dataContext = series.data[i];
        dataContext.x = data[i].x;
        dataContext.y = data[i].y;
        dataContext.radius = data[i].radius;
      }

      chart.invalidateRawData();

      label.text = year.toString();
    })


    function playSlider() {
      if (slider) {
        if (slider.start >= 1) {
          slider.start = 0;
          sliderAnimation.start();
        }

        sliderAnimation.setProgress(slider.start);

        sliderAnimation.resume();
        playButton.isActive = true;
      }
    }

    function stopSlider() {
      sliderAnimation.pause();
      playButton.isActive = false;
    }

    /*setTimeout(function() {
      playSlider()
    }, 2000);*/
      
  }

  drawChart2(data){
    console.log(data)
    let yearData = {};
    let firstYear = 2021;
    let lastYear = 2022;
    yearData[firstYear] = data;

    /*for (var year = firstYear; year < lastYear; year++) {
      let data = []
      yearData[firstYear] = data;

      for (var i = 0; i < 50; i++) {
        if (year == firstYear) {
          data.push({ x: Math.round(Math.random() * 180 - 90), y: Math.round(Math.random() * 140 - 70), radius: Math.round(Math.random() * 1000) });
        }
        else {
          let previous = yearData[year - 1][i];
          data.push({ 
            x: previous.x + Math.round(Math.random() * 8 - 4), 
            y: previous.y + Math.round(Math.random() * 8 - 4), 
            radius: Math.abs(previous.radius + Math.round(Math.random() * 200 - 100)) });
        }
      }
    }*/

    function findMinMax(key) {
      const datas1 = data.map((node) => node[key]);
      return {
        min: Math.min(datas1),
        max: Math.max(datas1),
      }
    }

    /*console.log(findMinMax('range'));
      console.log(findMinMax('time'));*/

    // Create chart instance
    let chart = am4core.create(this.chartInteracciones.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    let maxMinX = findMinMax('x')
    let maxMinY = findMinMax('y')

    // Create axes
    let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.title.text = 'CPR';
    xAxis.min = maxMinX.min;
    xAxis.max = maxMinX.max;
    xAxis.keepSelection = true;
    xAxis.renderer.grid.template.above = true;
    xAxis.renderer.labels.template.adapter.add("text", function(text) {
      return "$" +text;
    });

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.title.text = 'Proporción de resultados';
    yAxis.min = maxMinY.min;
    yAxis.max = maxMinY.max;
    yAxis.keepSelection = true;
    yAxis.renderer.grid.template.above = true;

    // Create color series
    
    //PROMY
    let promy = 0.0
    data.forEach(e => {promy = promy + parseFloat(e.y) });
    promy = promy / data.length;
    console.log("promy", promy)

    //PROMX
    let promx = 0.0
    data.forEach(e => {promx = promx + parseFloat(e.x) });
    console.log("promx", promx)
    promx = promx / data.length;
    console.log("promx", promx)

    // top right
    let series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.valueX = "ax";
    series4.dataFields.valueY = "ay";
    series4.strokeOpacity = 0;
    series4.fill = am4core.color("gray");
    series4.ignoreMinMax = true;
    series4.fillOpacity = 1;
    series4.data = [{
      "ax": promx * 0.99,
      "ay": 1000
    }, {
      "ax": promx,
      "ay": 1000
    }]

    // bottom right
    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueX = "ax";
    series3.dataFields.valueY = "ay";
    series3.strokeOpacity = 0;
    series3.fill = am4core.color("gray");
    series3.ignoreMinMax = true;
    series3.fillOpacity = 1;
    series3.data = [{
      "ax": 0,
      "ay": promy
    }, {
      "ax": 1000,
      "ay": (promy)
    }
    , {
      "ax": 1000,
      "ay": (0.99 * promy)
    }
    , {
      "ax": 0,
      "ay": (0.99 * promy)
    }
  ]



    // bubble series

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "y";
    series.strokeOpacity = 0;


    let bullet = series.bullets.push(new am4core.Circle());
    //bullet.fill = am4core.color("red");
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0.8;
    bullet.stroke = am4core.color("#ffffff");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "Nombre: {title}\n Gasto: ${value.value}\n CPR: ${valueX.value} \nResultados: {valueY.value}";


    bullet.events.on("over", function(event) {
      let target = event.target;
      chart.cursor.triggerMove({ x: target.pixelX, y: target.pixelY }, "hard");
      chart.cursor.lineX.y = target.pixelY;
      chart.cursor.lineY.x = target.pixelX - chart.plotContainer.pixelWidth;
      xAxis.tooltip.disabled = false;
      yAxis.tooltip.disabled = false;
    })

    bullet.events.on("out", function(event) {
      chart.cursor.triggerMove(event.pointer.point, "none");
      chart.cursor.lineX.y = 0;
      chart.cursor.lineY.x = 0;
      xAxis.tooltip.disabled = true;
      yAxis.tooltip.disabled = true;
    })

    series.heatRules.push({ target: bullet, min: 2, max: 30, property: "radius" });
    series.data = yearData[firstYear];



    // Scrollbars
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();




    let label = chart.plotContainer.createChild(am4core.Label)
    label.fontSize = 60;
    label.fillOpacity = 0.4;
    label.align = "center";
    label.zIndex = 1000;


    let sliderContainer = chart.bottomAxesContainer.createChild(am4core.Container);
    sliderContainer.width = am4core.percent(100);
    sliderContainer.layout = "horizontal";


    let playButton = sliderContainer.createChild(am4core.PlayButton);
    playButton.valign = "middle";
    playButton.events.on("toggled", function(event) {
      if (event.target.isActive) {
        playSlider();
      }
      else {
        stopSlider();
      }
    })
    playButton.disabled = true;

    let slider = sliderContainer.createChild(am4core.Slider);
    slider.valign = "middle";
    slider.margin(0, 0, 0, 0);
    slider.marginLeft = 30;
    slider.height = 15;

    slider.startGrip.events.on("drag", stop);
    slider.disabled = true;


    let sliderAnimation = slider.animate({ property: "start", to: 1 }, 80000, am4core.ease.linear).pause();
    sliderAnimation.events.on("animationended", function() {
      playButton.isActive = false;
    })

    slider.events.on("rangechanged", function() {
      let year = firstYear + Math.round(slider.start * (lastYear - firstYear - 1));
      let data = yearData[year];
      for (var i = 0; i < series.data.length; i++) {
        let dataContext = series.data[i];
        dataContext.x = data[i].x;
        dataContext.y = data[i].y;
        dataContext.radius = data[i].radius;
      }

      chart.invalidateRawData();

      label.text = year.toString();
    })


    function playSlider() {
      if (slider) {
        if (slider.start >= 1) {
          slider.start = 0;
          sliderAnimation.start();
        }

        sliderAnimation.setProgress(slider.start);

        sliderAnimation.resume();
        playButton.isActive = true;
      }
    }

    function stopSlider() {
      sliderAnimation.pause();
      playButton.isActive = false;
    }

    /*setTimeout(function() {
      playSlider()
    }, 2000);*/
      
  }
}

