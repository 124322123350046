import {Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';

import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-pauta-report',
  templateUrl: './pauta-report.component.html',
  styleUrls: ['./pauta-report.component.css']
})
export class PautaReportComponent {
  heading = 'Pauta';
  private projectName: string;
  private imageUrl: string = '';
  private projectId: number;
  private sectionType: any = [];
  private filterType: any = [];
  private cuentas: any = [];
  private accountList: any = [];
  private selectedIds: any = [];
  private cuentaIds: any = [];
  // private cuentaNames: any = [];

  public dateFrom: NgbDateStruct;
  public dateTo: NgbDateStruct;

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.cuentas[0] = this.cuentas[1] = this.cuentas[2] = this.cuentas[3] = true
    this.filterType[0] = this.filterType[1] = this.filterType[2] = false
    this.sectionType[0] = this.sectionType[1] = this.sectionType[2] = this.sectionType[3] = this.sectionType[4] = this.sectionType[5] = this.sectionType[6] = this.sectionType[7] = false
    
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];
      this.imageUrl = params['imageUrl'];
    });
    
    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }

    let data = {
      projectId : this.projectId
    }

    this.userService
      .getAccount(data)
      .subscribe((res:any)=>{
        this.accountList = res;
      },
      err => {
      }
    );
  }

  showPauta() {
    // console.log(this.cuentaIds);
    // return;
    if (this.cuentaIds == [] || !(this.sectionType[0] || this.sectionType[1] || this.sectionType[2] || this.sectionType[3] || this.sectionType[4] || this.sectionType[5] || this.sectionType[6] || this.sectionType[7]) || !(this.filterType[0] || this.filterType[1] || this.filterType[2]) || !this.dateFrom || !this.dateTo) {
      swal.fire('Please select the item.','','warning');
      return;
    }

    let data = {
      projectName : this.projectName,
      imageUrl : this.imageUrl,
      projectId : this.projectId,
      cuentaIds : this.cuentaIds,
      // cuentaNames : this.cuentaNames,
      sectionType : this.sectionType,
      filterType : this.filterType,
      dateFrom : this.dateFrom.year+"-"+this.dateFrom.month+"-"+this.dateFrom.day,
      dateTo : this.dateTo.year+"-"+this.dateTo.month+"-"+this.dateTo.day,
    }

    this.router.navigate(['/main/showpauta'], { queryParams: data });
  }

  accSelect() {
    if (this.selectedIds != [])
      this.cuentaIds = [];
      // this.cuentaNames = [];
      for (let index = 0; index < this.accountList.length; index++) {
        if(this.selectedIds.indexOf(this.accountList[index]['id']) != -1) {
          this.cuentaIds.push(this.accountList[index].id);
          // this.cuentaNames.push(this.accountList[index].name);
        }
      }
      // console.log(this.cuentaIds)
  }

  sectionClick (sectionParam) {
    this.sectionType[sectionParam] = !this.sectionType[sectionParam];
  }

  filterClick (filterParam) {
    this.filterType[filterParam] = !this.filterType[filterParam];
  }

  cuentasClick (cuentasParam) {
    this.cuentas[cuentasParam] = !this.cuentas[cuentasParam];
  }
}
