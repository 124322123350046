import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

import { ErrorHandlerService } from "./error-handler.service";  

@Injectable({
  providedIn: "root",
})

export class TableDataService {
  //private url = "http://localhost:3000";
  private url = "http://18.223.121.79:3000";

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type",
    "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE"
   }),
  };

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
    private router: Router
  ) {}

  getFaceBookData(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getFacebookData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getFacebookData")));
  }

  getTwitterData(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getTwitterData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getTwitterData")));
  }

  getInstagramData(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getInstagramData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getInstagramData")));
  }

  getYoutubeData(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getYoutubeData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getYoutubeData")));
  }
  getTikTokData(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getTikTokData", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getTikTokData")));
  }
  getFacebookIntTime(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getFacebookIntTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getFacebookIntTime")));
  }

  getInstagramIntTime(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getInstagramIntTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getInstagramIntTime")));
  }

  getTwitterIntTime(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getTwitterIntTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getTwitterIntTime")));
  }

  getFacebookFansTime(data) {
    return this.http
      .post<any>(this.url+"/quintlyApi/getFacebookFansTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getFacebookFansTime")));
  }
  
  getTwitterFansTime(data) {  
    return this.http
      .post<any>(this.url+"/quintlyApi/getTwitterFansTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getTwitterFansTime")));
  }

  getInstagramFansTime(data) {  
    return this.http
      .post<any>(this.url+"/quintlyApi/getInstagramFansTime", data, this.httpOptions)
      .pipe(catchError(this.errorHandlerService.handleError<any>("getInstagramFansTime")));
  }
  

  
}
