import {Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';

import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-capisci-report',
  templateUrl: './capisci-report.component.html',
  styleUrls: ['./capisci-report.component.css']
})
export class CapisciReportComponent {
  heading = 'Reporte';
  private projectName: string;
  private imageUrl: string = '';
  private accountList: any = [];
  private selectedIds: any;
  private projectId: number;
  private infotype: number;

  private section: any = [];
  private accountNum : number;

  public dateFrom: NgbDateStruct;
  public dateTo: NgbDateStruct;


  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.infotype = 2;

    this.section[0] = this.section[1] = this.section[2] = this.section[3] = this.section[4] = false
    this.accountNum = 4;
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];
      this.imageUrl = params['imageUrl'];
    });
    
    if (!this.projectId) {
      swal.fire('Debe seleccionar el proyecto.','','warning');
      this.router.navigate(['/main/project']);
    }

    let data = {
      projectId : this.projectId
    }

    this.userService
      .getAccount(data)
      .subscribe((res:any)=>{
        this.quitCeroCapi(res);
        this.accountList = res;
      },
      err => {
      }
    );
  }
  /*Esta funcion quita las cuentas donde idCapi sea = 0 */
  quitCeroCapi(data){
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      const element = data[i].idCapi;

      if (element == 0) { // only splice array when item is found
        element.splice(data[i], 1); // 2nd parameter means remove one item only
      }
      
    }



  }

  showArmChart() {
    if (!this.infotype || (!this.section[0] && !this.section[1] && !this.section[2] && !this.section[3] && !this.section[4]) || !this.dateFrom || !this.dateTo) {
      swal.fire('Please select the item.','','warning');
      return;
    }

    let data = {
      projectName : this.projectName,
      imageUrl : this.imageUrl,
      projectId : this.projectId,
      infotype : this.infotype,
      section : this.section,
      dateFrom : this.dateFrom.year+"-"+this.dateFrom.month+"-"+this.dateFrom.day,
      dateTo : this.dateTo.year+"-"+this.dateTo.month+"-"+this.dateTo.day,
      compareNum : this.accountNum,
    }
    this.router.navigate(['/main/armchart'], { queryParams: data });
  }

  infoClick (infoParam) {
    this.infotype = infoParam;
    if (this.infotype == 1) {
      this.accountNum = 0;
    } else {
      this.accountNum = 1;
    }
  }

  sectionClick (secParam) {
    this.section[secParam] = !this.section[secParam];
  }

  accNumClick (accParam) {
    if (this.infotype == 2) {
      this.accountNum = accParam;
    }
  }
}
