import { UserService } from "../../services/user.service";
import {Injectable, Inject} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class UserAuth implements CanActivate {

  constructor(private userService: UserService, private router: Router) {
    console.log("Inside Auth Guards");
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.userService.getToken() != null){
      if (new Date().getTime() > this.userService.tokenPayload.exp) {
        this.userService.deleteToken();
        this.router.navigate(['/user/login']);
        return false;
      }
      return true;
    }else {
      if(state.url.includes('/user/login')) {
       return true;
      }
      this.router.navigate(['/user/login']);
      return false;
    }
    return false;
  }
}